.sharedPost {}

.sharedPost .card-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 200px;
}

/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
/* Header */
.sharedPost .header {
    position: relative;
    width: calc(100% - 0px);
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3) !important;
    z-index: 1;
    transform: translateY(0);
    margin-left: 0;
    border-radius: 0;
    border-bottom-right-radius: 0px;
    box-shadow: 0 0 8px 8px var(--app-blue-10);
}

.sharedPost .header .titolo-and-logo {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0px;
    padding: 4px 8px;
    border-radius: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.sharedPost .header .titolo-and-logo .logo-container {
    position: relative;
    width: calc(var(--header-height) - 28px);
    height: calc(var(--header-height) - 28px);
    background-color: transparent;
    border-radius: 8px;
    margin-right: 8px;
}

.sharedPost .header .titolo-and-logo label {
    font-size: 16px;
    font-weight: 600;
    color: var(--app-blue);
    font-family: 'Cabin Sketch', sans-serif !important;
    margin-left: -1px;
}

/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */
/* Content Post */

.content-items.share {
    border: none;
    box-shadow: none;
    width: calc(100%);
    padding: 0;
    margin: 0 8px;
    padding-bottom: 200px;
}
.content-items.share .content-post {
    padding: 0;
    margin: 0
}

@media (min-width: 720px) {
    .comments-controlls-container.below-media-content {
        width: 0;
        height: 0;
        overflow: hidden;
        pointer-events: none;
    }
}

@media (max-width: 720px) {
    .content-items.horizontal.share {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: auto auto auto;
        gap: 20px;
    }
    
    .comments-controlls-container.above-media-content {
        width: 0;
        height: 0;
        overflow: hidden;
        pointer-events: none;
    }

    .content-items.share .image-view-scrollview.single {
        height: fit-content !important;
        width: calc(100vw - 16px) !important;
    }

    .content-items.share .image-view-scrollview.single .image-view-content-view {
        height: fit-content !important;
        width: 100% !important;
    }

    .content-items.share .image-view-scrollview.single img {
        height: fit-content !important;
        width: 100% !important;
    }
}

.content-items.share .content-post-by-container {
    margin-right: auto;
}

.content-items.share .date-of-post-container {
    margin-right: 8px;
}
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
/* Share Items */
.location-to-find-app-small.shared-post-item {
    position: relative;
    width: fit-content;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-bottom: max(100px, env(safe-area-inset-right) + 30px); */
}

.location-button-small.shared-post-link {
    margin-top: 0px;
    margin: 0;
    margin-left: 8px;
    padding: 8px;
    transition: none;
} 

.location-button-small.shared-post-link:last-of-type {
    margin-right: 8px;
}