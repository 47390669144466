.CareersPage-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: black;
}

.CareersPage {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
    border-radius: 20px;
}

.CareersPage .background-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    overflow: visible;
}

.CareersPage .content-view {
    position: relative;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.75);
}

.CareersPage .header-buttons.sign-in-up {
    box-shadow: none !important;
}

.CareersPage .page-title-header {
    position: relative;
    /* margin-top: 70px; */
    background: linear-gradient(transparent, var(--app-blue-15));
    background-color: black;
    height: 140px;
    display: grid;
    place-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.CareersPage .page-title-header h1 {
    color: white;
    font-size: 70px;
    /* font-family: "Cabin Sketch", sans-serif !important; */
}

.CareersPage .landing-image-container {
    position: relative;
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    /* border-bottom: 2px solid black; */
}

.CareersPage .landing-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    filter: blur(2.5px);
    opacity: 0.5;
}

.CareersPage .content {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
}

@media (max-width: 500px) {
    .CareersPage .content h1 {
        text-align: center;
    }
}

/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
.CareersPage .auth-view-header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    --auth-view-header: 70px;
    height: var(--auth-view-header);
    background: linear-gradient(rgba(0,0,0,0.12), rgba(0,0,0,0));
    background-color: black !important;
    border-radius: none;
    transition: 0.3s ease-in-out;
    z-index: 10000;
    /*  */
    display: flex;
    flex-direction: row;
    align-items: center;
    color: transparent;
    overflow: hidden;
}

.CareersPage .auth-view-header.scrolled { color: var(--app-blue) !important; }

.CareersPage .auth-view-header .auth-view-header-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CareersPage .auth-view-header .logo-title-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CareersPage .auth-view-header .logo-title-container .logo-container {
    position: relative;
    --auth-view-logo-height: calc(var(--auth-view-header) - 48px);
    width: var(--auth-view-logo-height);
    height: var(--auth-view-logo-height);
    background-color: transparent;
    border-radius: 8px;
    margin-left: 30px;
    transition: 0.3s ease-in-out;
    opacity: 1 !important;
}

.CareersPage .auth-view-header .logo-title-container .auth-app-title {
    position: relative;
    font-size: 20px;
    margin-left: 12px;
    color: white !important;
    /* font-family: 'Cabin Sketch', sans-serif !important; */
}

.CareersPage .auth-view-header .header-item-options {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-left: auto !important;
    margin-right: 30px;
}

.CareersPage .auth-footer-option {
    margin-left: 12px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}


.CareersPage .auth-footer-option:first-of-type { margin-left: 0; }


.CareersPage .location-to-find-app-small .location-button-small .location-title {
    color: white;
}