.commentSectionView {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200000000000000000000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.commentSectionView .comment-section-container {
    position: absolute;
    top: 0;
    width: 40vw;
    height: calc( 100% - 24px);
    background-color: rgba(255, 255, 255, 0.95);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);    
    margin-left: auto;
    z-index: 10;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: 0.35s;
    -webkit-transition: 0.35s;
    padding: 12px 24px;
}

.commentSectionView .comment-section-container .comment-section-title {
    position: relative;
    color: black;
    font-weight: 700;
    font-size: 30px;
}

.comment-section-click-away {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    backdrop-filter: blur(0.1px);
}

.commentSectionView .comment-section-content {
    position: relative;
    width: 100%;
    height: calc(100% - 12px);
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0;
}

.comment-section-content .comment-list-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 175px;
}

.commentSectionView .comment-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.0);
    padding-bottom: 16px;
    backdrop-filter: blur(2px);
}

.comment-section-content .comment-list-container .comment-item {
    position: relative;
    width: calc(100% - 16px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background: linear-gradient(90deg, rgba(0,0,0,0.07), rgba(0,0,0,0.05)) ;
    padding: 12px 8px;
    border-radius: 8px;
}

.comment-section-content .comment-list-container .comment-item  .comment-text {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    color: black;
    margin-top: 8px;
}

.comment-section-content .comment-list-container .comment-item .comment-user-info-and-date {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.comment-section-content .comment-list-container .comment-item .comment-user-info-and-date .time-of-post {
    position: relative;
    color: gray;
    font-weight: 500;
    font-size: 12px;
}

.comment-section-content .comment-list-container .comment-item .comment-user-info-and-date .comment-user-profile-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
}

.comment-section-content .comment-list-container .comment-item .comment-user-profile-info .first-last-username {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px
}

.comment-section-content .comment-item .comment-user-profile-info .first-last-username .first-last {
    font-size: 14px;
    font-weight: 600;
    color: black;
}


.comment-section-content .comment-item .comment-user-profile-info .first-last-username .username {
    font-size: 12px;
    font-weight: 500;
    color: var(--app-blue);
}

.comment-section-content .comment-item .comment-user-profile-info .comment-user-profile-image-container {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: gray;
    overflow: hidden;
    display: grid;
    place-items: center;
    z-index: 0;
}

.comment-section-content .comment-item .comment-user-profile-info .comment-user-profile-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.comment-section-content .comment-item .comment-user-profile-info .comment-user-profile-image-container .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.comment-section-content .comment-item .reply-top-comment-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    width: 100%;
}

.comment-section-content .comment-item .reply-top-comment-container .reply-textarea {
    position: relative;
    width: 100%;
    height: fit-content;
    border: 2px solid rgba(0,0,0,0.05);
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    padding: 4px;
    border-radius: 4px;
    resize: none;
    font-size: 14px;
}

.comment-section-content .comment-item .reply-top-comment-container .reply-textarea  .reply-button-containers {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.comment-section-container .comment-item .reply-top-comment-container .reply-to-comment-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 6px 8px;
    border-radius: 20px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.comment-section-container .comment-item .reply-top-comment-container .cancel-reply {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 6px 8px;
    border-radius: 20px;
    border: none;
    background-color: var(--app-red-10);
    color: var(--app-red);
    font-weight: 600;
    font-size: 14px;
}

.comment-section-content .comment-item .show-replies-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    border: none;
    border: 30px;
    padding: 4px 6px;
    background-color: var(--app-blue-10);
    color: var(--app-blue);
}

.replies-container {
    position: relative;
    width: calc(100% - 30px - 16px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background-color: rgba(0,0,0,0.1);
    padding: 8px;
    border-radius: 4px;
    margin-top: 12px;
}


.comment-container-nested {
    margin-top: 8px;
}


/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
/* Comments */
.commentSectionView .comments-controlls-container {
    position: relative;
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    background-color: rgb(225, 225, 225, 0.2);
    padding: 4px 0;
    border-radius: 4px;
    margin-top: 4px;
}

.commentSectionView .comment-send-button-container {
    position: relative;
    height: fit-content;
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 12px;
    margin-top: 12px;
    margin-left: 20px;
}
.commentSectionView .comment-send-button-container {
    grid-template-columns: 1fr auto;
    gap: 4px;
    width: calc(100% - 16px);
    margin-left: 8px;
}


.commentSectionView .comment-send-button-container .comment-area {
    position: relative;
    border-radius: 8px;
    padding: 8px 12px;
    resize: none;
    border: 2px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.10);
}

.commentSectionView .comment-send-button-container .comment-area-send-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    /*  */
    color: white;
    font-size: 12px;
    font-weight: 600;
    /*  */
    background-color: var(--app-blue);
    border: none;
    border-radius: 16px;
    padding: 8px 12px;
}