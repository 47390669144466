.DunbarColony {
    position: relative;
    height: calc( 100vh - var(--header-height) );
    width: calc( 100vw - var(--side-menu-width) );
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 0;
    overflow: hidden;
    border-top-left-radius: 0px;
}

.product .content-view.mobile .DunbarColony { 
    height: calc( 100vh );
    width: 100vw; 
}

.DunbarColony .dunbar-colony-enviorment {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    /* Backgrounds Styles */
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    opacity: 0.9;
}

.DunbarColony .dunbar-colony-content {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.05));
    background: linear-gradient( -90deg, var(--app-blue-10), rgba(255,255,255, 0));
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px);
}

/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
/* DUNBAR HEADER */
.DunbarColony .dunbar-colony-content .dunbar-header {
    position: absolute;
    width: calc(100% - 16px);
    height: fit-content;
    /* background: linear-gradient( 90deg, rgba(255, 255, 255, 0.8) , rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1)); */
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(12px);
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.07);
    margin-top: 0px;
    margin-left: 0px;
    z-index: 100000000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.DunbarColony .view-header-title { 
    font-size: 12px; 
    color: var(--app-blue);
    font-weight: 600;
    margin-left: 12px;
}

.profile-container {
    margin-left: auto;
}

.content-post-by-container {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.userProfile-img-content-container {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: gray;
    border-radius: 17px;
    border: 1px solid black;
    margin-left: 8px;
}

.profile-name-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.userProfile-name {
    font-size: 13px;
    margin-left: 8px;
    font-weight: 600;
}

.userProfile-nick-name {
    font-size: 12px;
    margin-left: 8px;
    font-weight: 500;
    color: var(--app-blue);
}

/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
/* Dunbar Colony Controller */
.DunbarColony .colony-controller {
    position: relative;
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.colony-pupose-butto-container {
    position: relative;
    width: 22px;
    height: 22px;
    margin-left: 8px;
}

.DunbarColony .show-long-explaination-button {
    position: relative;
    width: 22px;
    height: 22px;
    padding: 1px;
    /*  */
    background-color: rgba(0, 0, 0, 0.07);
    border: none;
    border-radius: 50%;
    /*  */
    font-size: 12px;
    font-weight: 600;
    /*  */
    transition: 0.3s;
    -webkit-transition: 0.3s;
    /*  */
    display: grid;
    place-items: center;
    text-align: center;
}
.DunbarColony .colony-controller .show-long-explaination-button:hover { 
    transform: scale(1.2);
    background-color: rgba(0, 0, 0, 0.1);
}

/* Subthread Controls */
/* Subthread Controls */
/* Subthread Controls */
.DunbarColony .colony-controller .colony-sub-threads {
    position: relative;
    height: 100%;
    width: calc(100% - 36px);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    margin-left: 16px;
}

.DunbarColony .colony-controller .colony-sub-thread-content-view {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    height: fit-content;
}

.DunbarColony .colony-controller .subthread-options {
    position: relative;
    padding: 6px 14px;
    background-color: black;
    color: white;
    border-radius: 50px;
    white-space: nowrap;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 600;
    /*  */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*  */
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}
.DunbarColony .colony-controller .subthread-options.active {
    background: linear-gradient(90deg, var(--app-blue), var(--app-green-70) );
    /* I think he thinks he's going to annoy me he will not. Keep in mind that your love shall sustain you... keep working... remember that this man decided to sit there */
    /* In the most absolute way he chose his suffering, not just once but twice */
    background-color: var(--app-blue);
}
.DunbarColony .colony-controller .subthread-options:hover { transform: scale(1.015);}
.DunbarColony .colony-controller .subthread-options:first-of-type { margin-left: 8px; }
.DunbarColony .colony-controller .subthread-options:last-of-type { margin-right: 30px; }

.DunbarColony .colony-controller .subthread-options .subthread-image-container {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 30px;
    background-color: gray;
    overflow: hidden;
}

.DunbarColony .colony-controller .subthread-options.default .subthread-image-container { width: 0 !important; margin: 0; padding: 0; }


.DunbarColony .colony-controller .subthread-options .subthread-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.DunbarColony .colony-controller .subthread-options label {
    margin-left: 8px;
}
.DunbarColony .colony-controller .subthread-options.default label { margin: 0;}


@media (max-width: 740px) {
    .DunbarColony .dunbar-colony-content .dunbar-header {
        position: absolute;
        width: 100%;
        height: 40px;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        grid-template-columns: 0px 0px 1fr;
    }
    
    .DunbarColony .view-header-title {
        height: 0px;
        width: 0px;
        overflow: hidden;
    }

    .DunbarColony .colony-controller {
        width: calc(100% - 22px);
    }

    .DunbarColony .colony-controller .colony-sub-threads {
        overflow: visible;
        overflow-x: scroll;
    }

    .DunbarColony .colony-controller .colony-sub-thread-content-view {
        width: 100%;
        padding-right: 60px;
    }

    .DunbarColony .colony-controller .subthread-options:last-of-type {
        margin-right: 100px;
    }
}

/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
/* DUNBAR TELOS */
.DunbarColony .dunbar-telos {
    position: absolute;
    top: 0;
    margin-top: 60px;
    z-index: 2000000000;
    width: calc(100% - 16px - 32px);
    padding: 20px 16px;
    margin-left: 7px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    backdrop-filter: blur(5px);
}

.dunbar-telos-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: visible;
    z-index: 0;
    opacity: 0.5;
}

.DunbarColony .dunbar-content-container {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10000;
    height: fit-content;
    width: fit-content;
}

.DunbarColony .close-and-title-container {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DunbarColony .dunbar-content-container .close-telos-button {
    position: relative;
    aspect-ratio: 1 / 1;
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    padding: 1px;
}
.DunbarColony .dunbar-content-container .close-telos-button:hover { transform: scale(1.2); }

.DunbarColony .dunbar-description-title {
    margin-left: 12px;
    font-size: 17px;
}



.DunbarColony .dunbar-content-container .dunbar-telos-content {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: 12px;
}

.DunbarColony .dunbar-content-container .dunbar-text-content {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: 4px;
    font-size: 14px;
    line-height: 1.8em;
}

/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
/* DUNBAR CHAT CONTAINER */
.DunbarColony .dunbar-colony-content .dunbar-colony-chat-view-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}



@media (max-width: 500px) {
    .DunbarColony .colony-controller .subthread-options {
        padding: 4px 10px;
        font-size: 11px;
    }
}