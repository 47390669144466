.commentSectionView.comment-section-mobile {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200000000000000000000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}


.commentSectionView.comment-section-mobile .comment-section-container {
    position: absolute;
    width: calc(100vw - 48px);
    height: calc(100% - 24px);
    padding: 12px 24px;
}

.commentSectionView.comment-section-mobile .comment-send-button-container .comment-area {
    position: relative;
    border-radius: 8px;
    padding: 8px 12px;
    resize: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
}