.delete-account-request-parent {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.delete-account-request-container {
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .delete-account-request-container h2 {
    text-align: center;
  }
  
  .delete-account-request-form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
  }
  
  .form-control {
    width: calc(100% - 40px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-primary {
    background-color: var(--app-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #166fe5;
  }
  

  .instructions {
    margin-top: 20px;
  }
  
  .instructions li {
    margin-bottom: 10px;
  }
  
  .error-message {
    color: #ff0000;
    margin-top: 10px;
  }