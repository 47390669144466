/* body {
    position: relative;
    background-color: white;
    margin: 0;
    font-family: Arial, sans-serif;
    color: white;
} */
#solar-system {
    width: 100vw;
    height: 100vh;
}
.orbit {
    fill: none;
    stroke: rgba(0, 0, 0, 0.05);
}
.planet {
    fill: #2093ff;
}

.mercury {
    fill: #708090;
}
.venus {
    fill: #ffc320;
}
.earth {
    fill: rgb(34, 200, 22);
}
.mars {
    fill: #ff2020;
}
.jupiter {
    fill: #ff5820;
}
.saturn {
    fill: #ffb120;
}
.uranus {
    fill: #20cbff;
}
.neptune {
    fill: #3220ff;
}


.dwarf-planet {
    fill: #cc6633;
}
.asteroid, .kuiper-object {
    fill: gray;
}
.label {
    font-size: 8px;
    fill: black;
}
.region-label {
    font-size: 12px;
    fill: #1cda3c;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
}