
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
/* *********** Other Options */
.product .content-view .other-options {
    position: relative;
    height: 100%;
    width: var(--other-options-content-width);
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(255, 255, 255, 0.9999), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.25));
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
}

.product .content-view .other-options .other-options-content-view {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 12px;
}


.product .content-view .other-options .live-chat-session-container {
    position: relative;
    width: calc(100% - 24px);
    height: fit-content;
    z-index: 0;
    margin-top: 20px;
    margin-left: 12px;
}

.product .content-view .other-options .live-chat-session-container .live-chat-card-container {
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 24px;
    background: linear-gradient(180deg, var(--app-blue-70), var(--app-blue-40));
    background-color: var(--app-green);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.35s;
    -webkit-transition: 0.35s;
    opacity: 0.9;
}

.product .content-view .other-options .live-chat-session-container .live-chat-card-container:hover {
    box-shadow: rgba(0, 0, 0, 24%) 0px 0px 40px -8px, rgba(0, 0, 0, 20%) 0px 0px 25px -16px;
    transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
    background-color: var(--app-red);
}

.product .content-view .other-options .live-chat-session-container .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: visible;
}

.product .content-view .other-options .live-chat-session-container .live-chat-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: fit-content;
    width: calc(100% - 40px);
    color: white;
    padding: 40px 20px;
}

.product .content-view .other-options .live-chat-session-container .live-chat-content .live-chat-title {
    font-size: 22px;
}

.product .content-view .other-options .live-chat-session-container .live-chat-content .live-chat-desciption {
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 8px;
    height: fit-content;
}






.product .content-view .other-options .post-content-option-container {
    position: relative;
    width: calc(100% - 24px);
    height: 180px;
    margin-left: 12px;
    border-radius: 24px;
    background-color: var(--app-blue);
    overflow: hidden;
    z-index: 0;
    display: grid;
    place-items: center;
    margin-top: 0;
    transition: 0.35s;
    -webkit-transition: 0.35s;
    opacity: 0.9;
}

.product .content-view .other-options .post-content-option-container:hover {
    box-shadow: rgba(0, 0, 0, 24%) 0px 0px 40px -8px, rgba(0, 0, 0, 20%) 0px 0px 25px -16px;
    transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
}

.product .content-view .other-options .post-content-option-container .colors-ui-container {
    opacity: 1 !important;
}

.product .content-view .other-options .post-content-option-container .post-content-option {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(30deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.15));
    color: white;
    font-weight: 600;
    font-size: 28px;
    transition: 0.35s;
    -webkit-transition: 0.35s;
    background-color: var(--app-blue);
}

.product .content-view .other-options .post-content-option-container .post-content-option:hover {
    background: linear-gradient(30deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.1));
}

.product .content-view .other-options .post-content-option-container .post-content-option .post-content-icon { 
    font-size: 40px;
    font-family: "M PLUS Rounded 1c" !important;
    font-weight: 800;
}


.product .content-view .other-options .live-chat-session-container .live-chat-card-container {
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 24px;
    background: linear-gradient(180deg, var(--app-blue-70), var(--app-blue-40));
    background-color: var(--app-green);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.35s;
    -webkit-transition: 0.35s;
    opacity: 0.9;
}


.product .content-view .other-options .live-chat-session-container.isAd {
    overflow: hidden;
}

.product .content-view .other-options .live-chat-session-container .live-chat-card-container.isAd {
    background: linear-gradient(180deg, var(--app-green-10), var(--app-green-10));
    width: calc(100% - 0px);
    padding: 0px;
    border-radius: 12px;
    overflow: hidden;
    display: grid;
    place-items: center;
}