.favoritesView {
    position: relative;
    background-color: rgba(255, 255, 255, 0.65);
    width: calc(100% - var(--side-menu-width));
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.favoritesView .favorites-content-view {
    position: relative;
    width: calc(100% - 12px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
}

.favoritesView .favorites-content-view .view-title {
    position: relative;
    width: calc(100% - 24px);
    height: fit-content;
    margin-left: 12px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
}

.favoritesView .favorites-content-view .view-title h1 {
    margin-top: 12px;
    color: black;
}

.favoritesView .favorites-content-view .view-title h4 {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 8px;
}


@media (max-width: 500px) {
    .favoritesView .favorites-content-view .content-items {
        width: calc(100% - 32px);
        margin-left: 12px;
    }
}