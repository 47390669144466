.epression-denotation {
    position: relative; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
}

.epression-denotation > span {
    margin-right: 4px !important;
}

.mathMode.calc-limit {
    position: relative; 
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0;
    margin-left: 4px;
    padding: 0;
}

.mathMode.calc-summation {
    position: relative; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
}

.mathMode.oneRowWrap {
    position: relative;
    display: inline-block;
    flex-direction: row;
    height: auto;
    width: calc(100% - 40px);
    /* display: flex; flex-direction: row;flex-wrap: wrap; */
}

.mathMode.math-fraction {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.mathMode.math-fraction numerator {
    position: relative;
    border-bottom: 2px solid gray;
    padding-bottom: 4px;
    text-align: center;
}


.mathMode.math-fraction denominator {
    position: relative;
    /* padding-bottom: 12px; */
    margin-top: 4px;
    text-align: center;
}