.SettingsView {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    transition: 0.35s ease-in-out;
    /*  */
    display: grid;
    place-items: center;
}

.SettingsView .close-button {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 30px;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(2px);
    border-radius: 12px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 600;
    font-size: 22px;
}

.SettingsView .settings-view-container {
    position: relative;
    width: 50%;
    height: fit-content;
    /* Padding of elements and padding between top and bottom of viewport */
    max-height: calc(100% - 60px - 60px);
    background-color: white;
    padding: 40px 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    transition: 0.3s ease-in-out;
}

.SettingsView .settings-view-container .setting-option {
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: center;
    color: var(--app-blue);
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    padding: 16px 0;
    background: linear-gradient(90deg, var(--app-blue-5), var(--app-green-5));
    border-radius: 16px;
}

.SettingsView .settings-view-container .setting-option:first-of-type {
    margin-top: 0;
}