.SearchView {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(10px);
    transition: 0.35s ease-in-out;
    /*  */
    display: grid;
    place-items: center;
    /*  */
    --searchView-close-button-dimensions: 40px;
    --searchView-close-button-margin: 20px;
    /*  */
    --search-view-container-border-radius: 12px;
    --search-view-container-padding: 30px;
}

.SearchView .close-button {
    position: absolute;
    width: var(--searchView-close-button-dimensions);
    height: var(--searchView-close-button-dimensions);
    left: var(--searchView-close-button-margin);
    top: calc(var(--searchView-close-button-margin) + env(safe-area-inset-top, 40px));
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(2px);
    border-radius: 12px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 600;
    font-size: 22px;
    z-index: 100000;
}

.SearchView .search-view-container {
    /*  */
    position: absolute;
    width: 67%;
    height: calc(100vh - var(--searchView-close-button-dimensions) - var(--searchView-close-button-margin) - var(--searchView-close-button-margin) - var(--searchView-close-button-margin)  - env(safe-area-inset-top, 40px));
    bottom: 0;
    /* Padding of elements and padding between top and bottom of viewport */
    background-color: white;
    padding: var(--search-view-container-padding) var(--search-view-container-padding) 0px var(--search-view-container-padding);
    border-top-left-radius: var(--search-view-container-border-radius);
    border-top-right-radius: var(--search-view-container-border-radius);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    overflow-y: scroll;
}

@media (max-width: 1000px) {
    .SearchView .search-view-container {
        width: calc(100% - var(--search-view-container-padding) - var(--search-view-container-padding));
    }
}

@media (max-width: 600px) {
    .SearchView .search-view-container {
        width: calc(100%);
        padding: 20px 0;
    }
}

/*  */
/*  */
/*  */
/*  */
.SearchView .search-view-container .search-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 100%;
    border-radius: 20px;
    transition: 0.3s;
    margin-top: 12px;
    -webkit-transition: 0.3s;
    --seach-container-size: 40px;
}

.SearchView .search-view-container .search-container .search-title {
    position: relative;
    font-size: 30px;
    margin-right: 20px;
}

.SearchView .search-view-container .search-container:hover { background-color: rgba(0, 0, 0, 0.005); }

.SearchView .search-view-container .search-container #search-input {
    position: relative;
    height: var(--seach-container-size);
    width: 500px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 8px;
    background-color: transparent;
}

.SearchView .search-view-container .search-container #search-input:focus {
    outline: 2px solid var(--app-blue);
    border-radius: 8px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

.SearchView .search-view-container .search-container .search-loading-card,
.SearchView .search-view-container .search-container #search-button {
    position: relative;
    height: var(--seach-container-size);
    width: 50px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0 solid black;
    display: grid;
    place-items: center;
}

.SearchView .search-view-container .search-container #search-button span.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
    font-size: 18px;
    color: rgb(0, 0, 0, 0.85);
}


/*  */
/*  */
/*  */
/*  */
/*  */
.SearchView .search-view-container .search-query-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.SearchView .search-view-container .search-query-container .search-query {
    position: relative;
    font-size: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
    font-weight: 900;
}


@media (max-width: 500px) {
    .SearchView .search-view-container .search-container .search-title {
        width: 0px;
        height: 0px;
        overflow: hidden;
        margin: 0;
    }

    .SearchView .search-view-container {
        --padding-top-search-container: calc(var(--searchView-close-button-dimensions) + var(--searchView-close-button-margin) + env(safe-area-inset-top, 40px));
        width: 100%;
        height: calc(100vh - var(--padding-top-search-container));
        padding: var(--search-view-container-padding) var(--search-view-container-padding) 0px var(--search-view-container-padding);
        padding-top: var(--padding-top-search-container);
    }

    .SearchView .close-button {
        background-color: rgba(0, 0, 0, 0.5);
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        color: white;
        z-index: 100000;
    }

    .SearchView .search-view-container .search-container {
        width: calc(100% - 20px);
        display: flex;
        /* grid-template-columns: 0px 1fr auto; */
    }

    .SearchView .search-view-container .search-container #search-input{
        width: calc(100% - 40px - 8px - 20px);
        margin-left: 20px;
        border-radius: 16px;
    }

    .SearchView .search-view-container .search-container .search-loading-card, 
    .SearchView .search-view-container .search-container #search-button {
        height: 40px;
        width: 40px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 8px;
    }
}


.SearchView.isMobileApp {
    width: calc(100vw) !important;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

.SearchView.isMobileApp .search-view-container {
    width: calc(100vw) !important;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

.SearchView.isMobileApp .search-view-container .search-container  {
    width: calc(100vw) !important;
    margin: 0;
    padding: 0;
    display: grid;
    place-items: center;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.SearchView.isMobileApp .search-view-container .search-container #search-input {
    width: calc(100vw - 20px - 16px) !important;
    margin: 0 !important;
    border-radius: 20px !important;
    margin: 0;
    padding: 0 8px;
}

.SearchView.isMobileApp .search-view-container .search-container #search-input:focus {
    border-radius: 20px !important;
    margin: 0;
    padding: 0
}


.SearchView.isMobileApp .close-button {
    display: grid;
    place-items: center;
    text-align: center;
    padding: 0;
}