
.LiveSession-main-container.side-menu-one {
    position: relative;
    z-index: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    transition: 0.6s ease-in-out;
}

.LiveSession-main-container.side-menu-one .LiveSession {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-contentview  {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
}

.LiveSession-main-container.side-menu-one .LiveSession .background-view {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 0;
    height: 0;
    overflow: visible;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-vstack {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-vstack.content-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    place-items: center;
    background: linear-gradient(transparent, var(--app-blue-10));
    background-color: white;
    border-radius: 0px;
    overflow: hidden;
    z-index: 1000000;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back {
    position: relative;
    height: fit-content;
    width: calc(100% - 40px);
    padding: 12px 20px;
    justify-content: flex-start;
    align-items: center;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .close-button {
    height: 36px;
    width: 36px;
    background: linear-gradient(var(--app-red-25), var(--app-red-5));
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px;
    color: var(--app-red);
    font-weight: 600;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .close-button:hover { transform: scale(1.1);}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .view-title .app-name,
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .view-title {
    position: relative;
    width: fit-content;
    font-size: 16px;
    color: black;
    display: inline-block;
    margin-left: 8px;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .app-name {
    color: var(--app-blue);
    margin-left: 20px;
    margin-right: 4px;
    font-family: 'Cabin Sketch', sans-serif !important;
    font-weight: 700;
    font-size: 28px;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .description-container {
    position: relative;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    text-align: left;
    margin-left: auto;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .report-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: 20px;
    margin-right: 8px;
    padding: 4px 12px;
    border-radius: 20px;
    background-color: var(--app-red-20);
    color: var(--app-red);
    border: none;
    transition: 0.3s ease-in-out;
}
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.title-and-back .report-button:hover { transform: scale(1.1); }
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
/* Content */
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container {
    position: relative;
    background-color: transparent;
    height: calc(100% - 16px);
    width: calc(100% - 48px);
    /* padding: 8px 24px; */
    padding: 0;
    /* Video-Chat-Container-Width */
    --video-chat-container-width: 430px;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .video-container {
    position: relative;
    width: var(--video-chat-container-width);
    height: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 18px;
    background: linear-gradient( var(--app-blue-15) , var(--app-blue-5) );
    background-color: transparent;
    overflow: hidden;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container {
    position: relative;
    height: calc(50% - 4px - 8px);
    width: 100%;
    background-color: black;
    border-radius: 18px;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container.user-two {
    margin-top: 4px;
    height: calc(50% + 8px);
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container .video-profile-image-container {
    position: relative;
    width: 100px;
    height: 100px;
    border: 3px solid white;
    background-color: gray;
    border-radius: 55px;
    overflow: hidden;
    z-index: 0;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container .video-profile-image-container img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container video {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    background-color: transparent;
    object-fit: cover;
}


.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-info-container {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: calc(100% - 24px);
    height: fit-content;
    padding: 12px 12px;
    background: linear-gradient(var(--app-blue-5), var(--app-blue-10));
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
}
.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-profile-image-container {
    position: relative;
    width: 36px;
    height: 36px;
    border: 2px solid black;
    background-color: gray;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}
.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-profile-image-container img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.LiveSession-main-container.side-menu-one .LiveSession .user-container .image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}


.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-info-container .username-nickname {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 12px;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-info-container .username-nickname .first-last {
    position: relative;
    font-size: 17px;
    color: white;
    font-weight: 600;
}

.LiveSession-main-container.side-menu-one .LiveSession .user-container .user-info-container .username-nickname .nickname {
    position: relative;
    font-size: 15px;
    color: var(--app-blue);
    font-weight: 600;
}


/*  */
/*  */
/*  */
/*  */
/*  */
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts {
    position: relative;
    width: calc(100% - var(--video-chat-container-width) - 12px - 40px);
    height: calc(100% - 24px);
    margin-left: 12px;
    padding: 12px 20px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    border-radius: 18px;
    overflow: hidden;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-telos {
    position: relative;
    width: fit-content;
    height: fit-content;
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-futher-description {
    position: relative;
    width: fit-content;
    height: fit-content;
    color: rgba(0, 0, 0, 0.75);
    font-size: 15px;
    font-weight: 500;
    margin-top: 8px;
}

/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
/* Prompt Items Container */
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompt-items-container {
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
}


.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompt-items-container .question-item {
    position: relative;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    color: var(--app-blue);
    margin-top: 50px;
}

/* Prompt Action Buttons */
/* Prompt Action Buttons */
/* Prompt Action Buttons */
/* Prompt Action Buttons */
/* Prompt Action Buttons */
/* Prompt Action Buttons */
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons {
    position: relative;
    width: calc(100% - 100px);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 50px;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .index-count-container {
    position: relative;
    width: 100px;
    height: 40px;
    background: rgba(0, 0, 0, 0.1);
    display: grid;
    place-items: center;
    border-radius: 22px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .search-for-session,
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .prev,
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .next {
    position: relative;
    width: 90px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 22px;
    --darkblue: rgb(40, 130, 214);
    --darkblue-10: rgb(40, 130, 214, 0.1);
    box-shadow: -2px 2px 12px 4px var(--darkblue-10), 2px 2px 12px  4px var(--darkblue-10);
    transition: 0.3s ease-in-out;
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .prev:hover,
.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .next:hover {
    transform: scale(1.1);
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .prev {
    background: linear-gradient(90deg, rgba(255,255,255, 0.125), var(--app-blue) );
    background-color: var(--app-blue);
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .prompts-action-buttons .next {
    background: linear-gradient(90deg, var(--app-blue) , rgba(255,255,255, 0.125));
    background-color: var(--app-blue);
}

.LiveSession-main-container.side-menu-one .LiveSession .livesession-hstack.content-container .prompts .search-for-session {
    width: fit-content;
    height: fit-content;
    background: linear-gradient(90deg, var(--app-blue-80) , rgba(255,255,255, 0.1), var(--app-blue-80));
    background-color: var(--app-green);
    margin: auto auto 20px auto;
    padding: 20px 24px 20px 24px;
}
