.HeaderItem {
    position: relative;
    width: calc(100% - 0px - 40px + 12px + 8px);
    height: 300px;
    margin-top: 8px;
    margin-left: 12px;
    display: grid;
    place-items: center;
    background-color: black;
    border-radius: 2px;
    overflow: hidden;
    z-index: 0;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.product .content-view.mobile .HeaderItem {
    height: 250px;
    width: calc(100% - 2px);
    margin-left: 0px;
    margin-top: 8px;
    border-radius: 0;
}


.HeaderItem .header-card {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, var(--app-green-5), var(--app-blue-70));
    background-color: white;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;

}

.HeaderItem .header-card .header-card-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    /*  */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
}

.HeaderItem .header-card .header-card-content-container {
    position: relative;
    height: fit-content;
    width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    color: white;
    z-index: 1000;
    padding: 20px 40px;
    margin-top: auto;
}

.HeaderItem .header-card .header-card-content-container .header-card-title {
    font-size: 20px;
    font-weight: 600;
    color: white;
}
.product .content-view.mobile .header-card-title {
    font-size: 16px;
}


.HeaderItem .header-card .header-card-content-container .header-card-description {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    max-width: 80%;
    color: white;
    margin-top: 12px;
}
.product .content-view.mobile .header-card-description { font-size: 14px; }

.HeaderItem .header-card .header-card-content-container .header-card-call-to-action-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 14px 22px;
    border-radius: 4px;
    color: black;
    text-decoration: none;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.5px;
}

.product .content-view.mobile .header-card-call-to-action-button {
    padding: 12px 16px;
    font-size: 12px;
}