.BottomTabBar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 0;
    visibility: hidden;
    pointer-events: none;
}

@media (max-width: 1084px) {
    .BottomTabBar {
        background-color: white;
        width: calc(100% - 20px);
        height: 70px;
        visibility: visible;
        pointer-events: all;
        z-index: 10000;
        /* padding-bottom: calc(env(safe-area-inset-bottom, 20px)); */
        margin-left: 10px;
        margin-bottom: calc(env(safe-area-inset-bottom, 20px) + 20px); 
        /* 20px as a fallback value */
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
        border-radius: 24px;
        /* transform: translateY(-20px); */
        /* bottom: calc(env(safe-area-inset-bottom, 20px) + 20px); */
    }

    .BottomTabBar.ios {
        margin-bottom: calc(env(safe-area-inset-bottom, 20px)); 
    }

    /* .BottomTabBar.mobile-app {
        width: 100%;
        margin: 0; 
        padding-bottom: calc(env(safe-area-inset-bottom, 20px));
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 0;
    } */
    .BottomTabBar.Messages,
    .BottomTabBar.DunbarColony {
        width: 100%;
        margin: 0; 
        padding-bottom: calc(env(safe-area-inset-bottom, 20px));
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 0;
    }

    .BottomTabBar .bottom-tabbar-options-container {
        position: relative;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        padding: 8px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: space-around;
    }


    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option {
        position: relative;
        height: calc(100% - 8px);
        width: fit-content;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
    }

    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option.active {
        background: linear-gradient( var(--app-blue-10), var(--app-blue-10) );
        background-color: var(--app-blue-10);
    }

    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option:first-of-type {
        margin-left: 12px;
    }
    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option:last-of-type {
        margin-right: 12px;
    }

    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option .tab-icon-container {
        position: relative;
        height: 20px;
        width: 20px;
        display: flex;
        flex-direction: column;
    }
    
    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option .tab-icon-container img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
    }

    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option .tab-icon-container .image-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }


    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option .tab-label {
        position: relative;
        font-size: 11px;
    }


    /*  */
    /*  */
    /*  */
    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option.post-button {
        height: calc(100% - 8px);
        aspect-ratio: 1 / 1 !important;
        /* background-color: red; */
    }

    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option.post-button .tab-icon-container {
        position: relative;
        height: 100%;
        width: 40px;
        display: flex;
        flex-direction: column;
    }
    
    .BottomTabBar .bottom-tabbar-options-container .bottom-tabbar-option .tab-icon-container img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
    }
}
