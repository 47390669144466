
.d-c-a-d-item {
    position: relative;
    width: 400px;
    min-width: 400px;
    height: fit-content;
    min-height: 60px;
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px auto 30px auto; /* { top: 30px left: auto bottom: 30px right: auto } */
    margin-left: 30px;
}

@media (max-width: 500px) {
    .d-c-a-d-item {
        width: calc(100% - 16px - 30px);
        min-width: calc(100% - 16px - 30px);
    }
}