.DirectMessage.Mobile-Version {
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100%);
    height: calc(100vh);
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
}


.DirectMessage.Mobile-Version  .messages-thread-and-controls {
    position: relative;
    width: calc(100%);
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}