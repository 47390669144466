body { 
    border-radius: none;
}

.LiveSessionMobile .user-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: black;
    border-radius: 0;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.LiveSessionMobile .user-container.user-one {
    height: 100vh;
    width: 100vw;
}

.LiveSessionMobile .user-container .video-profile-image-container {
    position: relative;
    width: 100px;
    height: 100px;
    border: 3px solid white;
    background-color: gray;
    border-radius: 55px;
    overflow: hidden;
    z-index: 0;
}

.LiveSessionMobile .user-container .video-profile-image-container img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.LiveSessionMobile .user-container video {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    background-color: transparent;
    object-fit: cover;
}


.LiveSessionMobile .user-container .user-info-container {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: calc(100% - 24px);
    height: fit-content;
    padding: 12px 12px;
    background: linear-gradient(var(--app-blue-5), var(--app-blue-10));
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
}
.LiveSessionMobile .user-container .user-profile-image-container {
    position: relative;
    width: 36px;
    height: 36px;
    border: 2px solid black;
    background-color: gray;
    border-radius: 24px;
    overflow: hidden;
    z-index: 0;
}
.LiveSessionMobile .user-container .user-profile-image-container img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.LiveSessionMobile .user-container .image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}


.LiveSessionMobile .user-container .user-info-container .username-nickname {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 12px;
}

.LiveSessionMobile .user-container .user-info-container .username-nickname .first-last {
    position: relative;
    font-size: 17px;
    color: white;
    font-weight: 600;
}

.LiveSessionMobile .user-container .user-info-container .username-nickname .nickname {
    position: relative;
    font-size: 15px;
    color: var(--app-blue);
    font-weight: 600;
}


.LiveSession.Mobile .user-container,
.LiveSession.Mobile .user-info-container {
    border-radius: 0px !important;
}