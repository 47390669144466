.ErrorMessage {
    position: fixed;
    bottom: 40px; /* 30px from the bottom of the view */
    left: 40px; /* left from the left edge 30px */
    z-index: 200000000000000000;/* Placing above all other content */
    /*  */
    display: grid;
    place-items: center;
    /*  */
    width: calc(100% - 80px);
    height: auto;
    border-radius: 13px;
    color: black;
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    background-color: white;
    /*  */
    box-shadow: 0 0 12px 3px rgba(0,0,0, 0.1);
    overflow: hidden;
}

.ErrorMessage .error-message-view-content {
    position: relative;
    width: calc(100% - 60px);
    padding: 40px 30px; /* TopBottom: 20, Sides: 30 */
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    -webkit-transition: 0.5s;
}

.ErrorMessage .error-message-view-content .error-message-background {
    position: absolute;
    width: 0;
    height: 0;
    overflow: visible;
    top: 0;
    left: 0;
    z-index: 0;
}

.ErrorMessage .error-message-view-content .error-message-content {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.ErrorMessage .error-message-view-content .error-message-content .error-message-explaination {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Error Message Icon */
.ErrorMessage .error-message-view-content .error-message-icon-container {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    z-index: 0;
    overflow: hidden;
    margin-right: 20px;
    /* Temporary Waiting For Icons */
    background-color: gray;
}

.ErrorMessage .error-message-view-content .error-message-icon-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ErrorMessage .error-message-view-content .error-message-icon-container .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}


/* Close Button */
.ErrorMessage .error-message-view-content .close-error-message {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 4px;
    background-color: rgba(0,0,0, 0.3);
    z-index: 3000000000000;
}