.start-session-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000000000000;
    backdrop-filter: blur(5px);
  }
  
  .start-session-modal {
    background-color: white;
    border-radius: 20px;
    padding: 24px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.15);
  }
  
  .start-session-modal h2 {
    color: var(--app-blue);
    margin-bottom: 10px;
  }
  
  .start-session-modal p {
    color: #666;
    margin-bottom: 20px;
  }
  
  .start-session-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .start-session-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .cancel-button {
    background: linear-gradient(270deg, var(--app-blue-10), var(--app-red-10) );
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
    margin-right: 10px;
  }
  
  .confirm-button {
    background: linear-gradient(90deg, var(--app-blue), var(--app-green-60) );
    background-color: var(--app-blue-80);
    color: white;
  }