.FilterPopUp {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: 100vw;
    z-index: 1000000;
    background-color: white;
    backdrop-filter: blur(10px);
    transition: 0.35s ease-in-out;
    -webkit-transition: 0.35s ease-in-out;
    /*  */
    display: grid;
    place-items: center;
}