.FilterView {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background: linear-gradient(rgba(0,0,0,0), var(--app-blue-20));
    background-color: white;
}

.FilterView h1, 
.FilterView h2, 
.FilterView h3, 
.FilterView h4,
.FilterView h5,
.FilterView h6,
.FilterView p,
.FilterView label,
.FilterView a,
.FilterView span,
.FilterView div,
.FilterView img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.FilterView .scrollview {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.FilterView .contentview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
}

.FilterView.is-app-mobile .contentview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding-top: calc(env(safe-area-inset-top) + 30px);
    padding-bottom: env(safe-area-inset-bottom);
}

/* Cancel Button */
/* Cancel Button */
/* Cancel Button */
/* Cancel Button */
.FilterView.is-app-mobile .cancel-container {
    position: absolute;
    top: calc(env(safe-area-inset-top) + 4px);
    left: 12px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 12px;
    padding: 8px 12px;
    box-shadow: 0px 1px 1px 0px rgba(50, 50, 93, 0.25);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    z-index: 10000;
}

.FilterView.is-app-mobile .cancel-container .cancel-label {
    color: var(--app-red);
}

/* One Sentence Explaination */
/* One Sentence Explaination */
/* One Sentence Explaination */
/* One Sentence Explaination */
.sentence-prompt-container {
    position: relative;
    width: calc(100vw);
    height: fit-content;
    margin: 0;
    margin-top: 20px;
    display: grid;
    place-items: center;
    border-radius: 20px;
    background-color: var(--app-green-10);
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.sentence-prompt-container .oneSentence-content-view {
    position: relative;
    width: calc(100% - 40px);
    height: fit-content;
    padding: 30px 20px;
}

.sentence-prompt-container .oneSentence-content-view .oneSentence-field-and-items {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sentence-prompt-container .oneSentence-content-view .oneSentence-field-and-items .content-title {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.sentence-prompt-container .oneSentence-content-view .oneSentence-field-and-items .content-description {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: black;
    margin-top: 8px;
    margin-bottom: 8px;
}

.sentence-prompt-container .oneSentence-content-view .oneSentence-field-and-items .oneSentence-field {
    position: relative;
    width: calc(100% - 16px);
    height: 40px;
    background-color: white;
    border: none;
    /* border: 1px solid rgba(0,0,0,0.25); */
    border-radius: 21px;
    padding: 4px 8px;
    margin-top: 20px;
    font-size: 17px;
}




/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
/* User Interests */
.FilterView .topics-of-inteterst-scrollview-view {
    position: relative;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.FilterView .topics-of-interets-content-view {
    position: relative;
    width: calc( 100% - 40px);
    height: fit-content;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--app-blue-10);
    border-radius: 20px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

.FilterView .topics-of-interets-content-view .topics-of-interest-title {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 24px;
    color: black;
    font-weight: 600;
}

.FilterView .topics-of-interets-content-view .topics-of-interest-description {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 18px;
    color: gray;
    font-weight: 500;
    margin-top: 8px;
}

.topics-of-interest-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.FilterView .topic-item {
    position: relative;
    width: fit-content;
    height: fit-content;
    /* margin-left: 20px; */
    background-color: black;
    color: white;
    font-weight: 600;
    font-size: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    padding: 8px 12px;
    border-radius: 20px;
    margin-left: 14px;
    margin-top: 14px;
    /*  */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.FilterView .topic-item.active { background-color: var(--app-blue); } 
.FilterView .topic-item:hover { transform: scale(1.05);}

/* Custom Interests */
.FilterView .topics-of-inteterst-scrollview-view.interests {}

.FilterView .topics-of-inteterst-scrollview-view.interests .topics-of-interets-content-view {
    background-color: var(--app-yellow-10);

}

/* .FilterView .topics-of-inteterst-scrollview-view.interest .topic-title {} */

.FilterView .topic-item .remove-item {
    position: relative;
    height: 28px;
    width: 28px;
    color: white;
    background: var(--app-yellow-70);
    margin-left: 12px;
    border-radius: 14px;
    border: none;
}

.FilterView .interest-field-and-items {
    position: relative;
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 54px;
    place-items: center;
    gap: 12px;
    margin-top: 20px;
}

.FilterView .interest-field-and-items .interest-field {
    position: relative;
    width: calc(100% - 12px - 16px);
    height: calc(100% - 8px);
    padding: 4px 8px;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    font-size: 17px;
}

.FilterView .interest-field-and-items .add-interest {
    position: relative;
    width: 54px;
    height: 100%;
    border: none;
    background-color: var(--app-yellow);
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
}

/* One Sentence */

.FilterView .oneSentence-field-and-items {
}

.FilterView .topics-of-inteterst-scrollview-view.oneSentence .topics-of-interets-content-view  {
    background-color: var(--app-green-10);
}


/* save-button */
.FilterView .save-button {
    position: relative;
    width: fit-content;
    height: 80px;
    border: none;
    background: linear-gradient(var(--app-green-30), var(--app-blue));
    background-color: var(--app-blue);
    color: white;
    font-size: 28px;
    font-weight: 600;
    border-radius: 24px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 12px 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}
