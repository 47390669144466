.product .header {
    position: relative;
    width: calc(100% - 0px);
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8999999);
    z-index: 1;
    transform: translateY(0);
    margin-left: 0;
    border-radius: 0;
    border-bottom-right-radius: 8px;
    /* padding-top: env(safe-area-inset-top); */
    /* margin-top: env(safe-area-inset-top); */
    /* margin-top: 100px; */
}

@media (max-width: 500px) {
    .product .header {
        padding-top: env(safe-area-inset-top, 40px);
    }
}

.product .header .titolo-and-logo {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    padding: 4px 8px;
    border-radius: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.product .header .titolo-and-logo:hover { background-color: rgba(0, 0, 0, 0.05); }


.product .header .titolo-and-logo label {
    font-size: 18px;
    font-weight: 600;
    margin-left: 4px;
    color: var(--app-blue);
    font-family: 'Cabin Sketch', sans-serif !important;
    margin-left: -1px;
}

.product .header .titolo-and-logo .logo-container {
    position: relative;
    width: calc(var(--header-height) - 22px);
    height: calc(var(--header-height) - 22px);
    background-color: transparent;
    border-radius: 8px;
    margin-right: 8px;
}

.product .header .app-constitution-button {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    margin-left: 2px;
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0.015);
}
.product .header .app-constitution-button:hover {
    transform: scale(1.1);
    background-color: rgb(0, 0, 0, 0.15);
}

.product .header .app-constitution-button img {
    position: relative;
    width: 16px;
    height: 16px;
    object-fit: contain;
    z-index: 0;
}

.product .header .app-constitution-button .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
/* Search */
.product .header .search {
    position: relative;
    display: grid;
    place-items: center;
    width: fit-content;
    height: 100%;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
}

.product .header .search .product-search-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 30px;
    width: fit-content;
    border-radius: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.product .header .search .product-search-container:hover { background-color: rgba(0, 0, 0, 0.05); }

.product .header .search .product-search-container #search-input {
    position: relative;
    height: 100%;
    width: 400px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 8px;
    background-color: transparent;
}

.product .header .search .product-search-container #search-button {
    position: relative;
    height: 100%;
    width: 30px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0 solid black;
    display: grid;
    place-items: center;
}

.product .header .search .product-search-container #search-button span.material-symbols-outlined {
    font-family: 'Material Symbols Outlined' !important;
    font-size: 16px;
    color: rgb(0, 0, 0, 0.85);
}

/* Userprofile name/image and notifications  */
/* Userprofile name/image and notifications  */
/* Userprofile name/image and notifications  */
.product .header .profile-img-username-notifications {
    position: relative;
    width: fit-content;
    height: auto;
    margin-right: 20px;
    background-color: rgba(0, 0, 0, 0.009);
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    padding: 4px;
}

.product .header .profile-img-username-notifications:hover { background-color: rgba(0, 0, 0, 0.15); }

.product .header .profile-img-username-notifications .userProfile-img-container {
    position: relative;
    width: calc(var(--header-height) - 16px);
    height: calc(var(--header-height) - 16px);
    border-radius: 17px;
    background-color: gray;
    border: 1px solid black;
}

.product .header .profile-img-username-notifications .userproile-name {
    position: relative;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    margin-left: 8px;
}

.product .header .profile-img-username-notifications .notifications {
    position: relative;
    width: calc(var(--header-height) - 24px);
    height: calc(var(--header-height) - 24px);
    border-radius: 14px;
    background-color: rgb(197, 197, 197);
    background-color: rgba(0, 0, 0, 0.025);
    margin-left: 8px;
    display: grid;
    place-items: center;
}

.product .header .profile-img-username-notifications .notifications img {
    position: relative;
    width: calc(100%);
    height: calc(100%);
    object-fit: contain;
    transform: scale(0.65);
    opacity: 0.7;
}

.product .header .profile-img-username-notifications .notifications .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@media (max-width: 1084px) {

    /* .product .header {
      width: 0px !important;
      height: 0px !important;
      pointer-events: none !important;
      overflow: hidden !important;
    } */

    .product .header.not-mobile-app .app-constitution-button,
    .product .header .profile-img-username-notifications .notifications,
    .product .header.not-mobile-app .search,
    .product .header h6 {
      width: 0px !important;
      height: 0px !important;
      pointer-events: none !important;
      overflow: hidden !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    .product .header .titolo-and-logo {
        margin-left: 0 !important;
    }

    .product .header.not-mobile-app .profile-img-username-notifications .userProfile-img-container {
        width: 0px !important;
        height: 0px !important;
        pointer-events: none !important;
        overflow: hidden !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

@media (max-width: 600px) {
    .product .header .search {
        width: 34px !important;
        height: 34px !important;
        pointer-events: all !important;
        overflow: hidden !important;
        background-color: white;
        border-radius: 18px;
        border: 2px solid rgba(0, 0, 0, 0.075);
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        margin-left: auto;
        margin-right: 0px;
    }

    .product .header .search .product-search-container {
        position: relative  !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        display: flex  !important;
        justify-content: center  !important;
        align-items: center !important;
    }

    .product .header .search .product-search-container #search-button {
        position: relative  !important;
        width: fit-content !important;
        height: fit-content !important;
        padding: 0 !important;
        margin: 0 !important;
        border: none;
    }

    .product .header .search .product-search-container #search-button .material-symbols-outlined {
        background-color: transparent;
        width: fit-content !important;
        height: fit-content !important;
        border: none;
    }

    .product .header .search .product-search-container input {
        width: 0px !important;
        height: 0px !important;
        pointer-events: none !important;
        overflow: hidden !important;
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
      }
  
      .product .header.mobile-app .profile-img-username-notifications {
        width: fit-content !important;
        height: fit-content !important;
        padding: 0 !important;
        margin: 0 !important;
        margin-left: 12px !important;
        margin-right: 20px !important;
      }

      .product .header.mobile-app .profile-img-username-notifications .userproile-name {
            width: 0px !important;
            height: 0px !important;
            pointer-events: none !important;
            overflow: hidden !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    .product .header.not-mobile-app .app-constitution-button
    .product .header.not-mobile-app .profile-img-username-notifications .userProfile-img-container {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}