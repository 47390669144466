.NotificationView {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000000000000;
    transition: 0.35s ease-in-out;
}

.NotificationView .collapse-view {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
}

.NotificationView .notus-content-view {
    position: absolute;
    width: calc(500px);
    height: calc(100% - 24px);
    z-index: 10;
    padding: 12px 20px;
    top: 0;
    right: 0;
    background: linear-gradient(transparent, var(--app-blue-5));
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
}


.NotificationView .notus-content-view .notification-content-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    /* background-color: red; */
}

.NotificationView .notus-content-view .notification-v-stack {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}


.NotificationView .notus-content-view .notification-v-stack .pop-out-title {
    position: relative;
    font-size: 28px;
    color: var(--app-blue);
    font-weight: 600;
    margin-top: 12px;
}

.NotificationView .notifications-items-container {
    position: relative;
    height: fit-content;
    width: 100%;
    margin-top: 12px;
}

/* Notification Items */
/* Notification Items */
.NotificationView .NotificationItem {
    position: relative;
    width: calc(100% - 24px);
    height: fit-content;
    padding: 20px 12px;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 8px;
    border-radius: 12px;
    overflow: hidden;
}

.NotificationItem .date-from-container {
    position: relative;
    width: calc(100% - 24px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
}

.NotificationItem .date-from-container .notiification-from-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    align-items: center;
}

.NotificationItem .date-from-container .notiification-from-container .profile-image-container {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 2px solid black;
    overflow: hidden;
    z-index: 0;
}
.NotificationItem .date-from-container .notiification-from-container .profile-image-container img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}
.NotificationItem .date-from-container .notiification-from-container .profile-image-container .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
}

.NotificationItem .date-from-container .notiification-from-container .username-nickname-conatiner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-start;
    margin-left: 8px;
}

.NotificationItem .date-from-container .notiification-from-container .username-nickname-conatiner .first-name {
    position: relative;
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}
.NotificationItem .date-from-container .notiification-from-container .username-nickname-conatiner .nickname {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    color: var(--app-blue);
}


.NotificationItem .date-from-container .date-time {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
}


.NotificationItem .notification-message-container {}
.NotificationItem .notification-content {
    margin-top: 8px;
}



