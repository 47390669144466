/* body, html {
    height: 100vh !important;
    height: -webkit-fill-available !important;
    width: 100vw !important;
    width: -webkit-fill-available !important;
}

body, html {
    min-height: 100vh !important;
    min-height: -webkit-fill-available !important;
    min-width: 100vw !important;
    min-width: -webkit-fill-available !important;
} */

.mobile-movie-player {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    min-width: 100%;
    max-width: 100%;
    height: 100vh;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.mobile-movie-player mux-player {
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.mobile-movie-player.fullscreen mux-player {
    width: 100%;
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0 auto;
}