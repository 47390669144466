.liveSessionEmbededView {
    position: relative;
    width: calc(100% - 24px);
    margin: 18px 0 12px 12px;
    height: 400px;
    background: linear-gradient( transparent , var(--app-blue-10));
    background-color: white;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: grid;
    place-items: center;
}

.liveSessionEmbededView .background-view {
    position: absolute;
    width: 0;
    height: 0;
    overflow: visible;
    opacity: 0.85;
}

.liveSessionEmbededView .liversess-card-content-view {
    position: relative;
    width: fit-content;
    max-width: calc(100% - 80px);
    height: fit-content;
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    z-index: 10;
    padding: 20px 40px;
    background-color: transparent;
}

.liveSessionEmbededView .liversess-card-content-view .live-sess-card-title {
    position: relative;
    font-size: 40px;
    color: rgba(0,0,0,1);
}

.liveSessionEmbededView .liversess-card-content-view .live-sess-explainaion {
    font-size: 30px;
    color: rgba(0,0,0,0.75);
    margin-top: 20px;
}

.liveSessionEmbededView .liversess-card-content-view .live-sess-secondary-explainaion {
    font-size: 20px;
    color: rgba(0,0,0,0.5);
    margin-top: 12px;
}

.liveSessionEmbededView .liversess-card-content-view .live-sess-call-to-action {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 22px;
    font-weight: 600;
    color: white;
    margin-top: 30px;
    padding: 16px 24px;
    background-color: var(--app-blue);
    border-radius: 30px;
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}