.DirectMessage {
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100% - var(--side-menu-width));
    height: calc(100vh - var(--header-height));
    background-color: rgba(255, 255, 255, 1);
    /* border-top-left-radius: 8px; */
    overflow: hidden;
    --message-thread-list-width: 270px;
}

/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
/* Message Thread List */
.MessageThreadOptionList {
    position: relative;
    width: var(--message-thread-list-width);
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(5px);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.MessageThreadOptionList .message-thread-scrollview {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    overflow-y: scroll;
}

.MessageThreadOptionList .message-thread-scrollview .message-thread-contentview {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
}

/* Message Thread List Options */
/* Message Thread List Options */
/* Message Thread List Options */
.MessageThreadOptionList .chat-user-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(100% - 28px);
    height: fit-content;
    padding: 14px;
    border-radius: 8px;
    overflow: hidden;
}

.MessageThreadOptionList #active.chat-user-item {
    position: relative;
    background: linear-gradient( var(--app-blue-10), var(--app-green-10));
    background-color: var(--app-blue-10);
}

    .MessageThreadOptionList .chat-user-item .chat-user-item-data {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
    }
        .MessageThreadOptionList .chat-user-item .chat-user-item-data .chat-user-username-profile-image {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: fit-content;
        }
            .MessageThreadOptionList .chat-user-item .chat-user-item-data .chat-user-username-profile-image .other-user-profile-image-container {
                position: relative;
                width: 34px;
                height: 34px;
                border-radius: 20px;
                overflow: hidden;
                border: 2px solid black;
                z-index: 0;
                display: grid;
                place-items: center;
            }
                .MessageThreadOptionList .chat-user-item .chat-user-item-data .chat-user-username-profile-image .other-user-profile-image {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    z-index: 0;
                }
                .MessageThreadOptionList .chat-user-item .chat-user-item-data .chat-user-username-profile-image .image-cover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 100000;
                    width: 100%;
                    height: 100%;
                }

            .MessageThreadOptionList .chat-user-item .chat-user-username-profile-image .user-name {
                position: relative;
                font-size: 14px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                margin-left: 12px;
            }


        .MessageThreadOptionList .chat-user-item .chat-user-item-data .nick-name {
            position: relative;
            font-size: 14px;
            font-weight: 600;
            color: var(--app-blue);
        }

        .MessageThreadOptionList .chat-user-item .chat-user-item-data .time-from-last-message {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 8px;
            z-index: 10000000;
            color: rgba(0, 0, 0, 0.25);
        }   

        .MessageThreadOptionList .chat-user-item .chat-user-item-data .last-message {
            position: relative;
            font-weight: 500;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            margin-top: 12px;
        }


/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
/* Message Thread */
.DirectMessage .messages-thread-and-controls {
    position: relative;
    width: calc(100% - var(--message-thread-list-width));
    height: calc(100vh - var(--header-height) - 100);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.DirectMessage .messages-thread-and-controls .message-thread-scrollview {
    position: relative;
    width: calc(100%);
    height: calc(100%);
    overflow: hidden;
    overflow-y: scroll;
}

.DirectMessage .messages-thread-and-controls .message-thread-contentview {
    position: relative;
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: 0;
    flex-shrink: 0;
    padding: 30px 20px 162px 20px;
}

/* Message Items */
/* Message Items */
/* Message Items */
/* Message Items */
/* Message Items */
/* Message Items */
/* Message Items */
/* Message Items */
.DirectMessage .message-thread-contentview .message-item {
    position: relative;
    width: 100%;
    height: fit-content;
    display: block;
    margin-top: 12px;
}

.DirectMessage .message-thread-contentview .message-item .chat-session-time {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    color: rgba(0, 0, 0, 0.175);
}
/* .DirectMessage .message-thread-contentview .message-item.this-user {}
.DirectMessage .message-thread-contentview .message-item.other-user {} */

.DirectMessage .message-thread-contentview .message-item .message-item-image {
    position: relative;
    max-width: 40%;
    height: auto;
    border-radius: 20px;
    border: 2px solid black;
    object-fit: contain;
}
.DirectMessage .message-thread-contentview .message-item.this-user .message-item-image {
    left: 100%;
    transform: translateX(-100%);
}
.DirectMessage .message-thread-contentview .message-item.other-user .message-item-image {
    left: 0%;
    transform: translateX(0%);
}

.DirectMessage .message-thread-contentview .message-item .message-container {
    position: relative;
    width: fit-content;
    height: auto;
    border-radius: 16px;
    background-color: orange;
    padding: 10px 16px;
    font-size: 14px;
    max-width: 45%;
}
.DirectMessage .message-thread-contentview .message-item.this-user .message-container {
    background: linear-gradient(90deg, var(--app-blue-85), var(--app-green-30));
    background-color: var(--app-blue-85);
    left: 100%;
    transform: translateX(-100%);
    border-bottom-right-radius: 2px;
}
.DirectMessage .message-thread-contentview .message-item.other-user .message-container {
    background: linear-gradient(90deg, var(--app-green-85), var(--app-blue-30));
    background-color: var(--app-green-85);
    left: 0%;
    transform: translateX(0%);
    border-bottom-left-radius: 2px;
}

.DirectMessage .message-thread-contentview .message-item .message-container .message {
    color: white;
    font-weight: 600;
}
.DirectMessage .message-thread-contentview .message-item.this-user .message-container .message {}
.DirectMessage .message-thread-contentview .message-item.other-user .message-container .message {}
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
/* Message Controls -> TextField Send Media Button */
.DirectMessage .messages-thread-and-controls .message-controls {
    position: absolute;
    bottom: 12px;
    left: 0;
    z-index: 100000000;
    width: calc(100% - 24px);
    margin-left: 12px;
    height: 100px;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 12px;
}


.DirectMessage .messages-thread-and-controls .chat-box-container {
    position: relative;
    height: calc(100% - 12px - 3px);
    width: calc(100% - 12px - 3px - 120px);
    padding: 6px;
    /*  */
    z-index: 1;
}

.DirectMessage .messages-thread-and-controls .chat-box-container .chat-box-media-mode {
    position: relative;
    height: calc(100%);
    max-height: 100px;
    width: calc(100%);
    margin-top: 4px;
    overflow: hidden;
    background-color: transparent;
    /*  */
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 12px;
}

.DirectMessage .messages-thread-and-controls .chat-box-container .chat-box {
    position: relative;
    --chat-font-font-size: 14px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    padding: 8px;
    border: none;
    border-radius: 8px;
    font-size: var(--chat-font-font-size);
    background-color: transparent;
    resize: none;
    color: black;
    overflow: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.05);
}

.DirectMessage .messages-thread-and-controls .chat-box:empty:before {
    content: attr(placeholder);
    position: absolute;
    left: 0;
    color: #aaa;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    direction: ltr;
    padding: 0px 12px;
    font-family: var(--chat-font-family);
    font-size: var(--chat-font-font-size);
}

.DirectMessage .messages-thread-and-controls .chat-box img {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-height: 80px;
    object-fit: contain;
    border-radius: 15px;
}


.DirectMessage .dunbar-chat-container .chat-box-container .chat-box-media-mode {
    position: relative;
    height: calc(100%);
    max-height: 100px;
    width: calc(100%);
    margin-top: 4px;
    overflow: hidden;
    background-color: transparent;
    /*  */
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 12px;
}

.DirectMessage .chat-box-media-mode .image-select {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    height: calc(100% - 4px);
    aspect-ratio: 1 / 1;
    border: 1.5px solid black;
    border-radius: 12px;
    overflow: hidden;
    display: grid;
    place-items: center;
    z-index: 0;
    backdrop-filter: blur(3px);
}

.DirectMessage .chat-box-media-mode .image-select .image-mode-select-label {
    position: relative;
    color: var(--app-blue);
    font-style: italic;
    font-size: 14px;
    color: black;
}

.DirectMessage .chat-box-media-mode .image-select img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.DirectMessage .chat-box-media-mode .image-select input {
    position: absolute;
    visibility: none;
    display: none;
    z-index: 1000;
    height: 100%;
    width: 100%;
}

.DirectMessage .chat-box-media-mode .image-select input::file-selector-button {
    visibility: none;
    display: none;
}



.DirectMessage .messages-thread-and-controls .chat-box-container .chat-box-media-mode .chat-box-image-mode-textarea {
    position: relative;
    --chat-font-family: 'Roboto';
    --chat-font-font-size: 14px;
    height: calc(100% - 24px); /* Includes Padding, 2px border, a little extra space, and top margin */
    max-height: 100px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 8px 12px;
    border: 2px solid var(--app-blue);
    border-radius: 12px;
    font-family: var(--chat-font-family);
    font-size: var(--chat-font-font-size);
    background-color: transparent;
    resize: none;
    color: white;
}












.DirectMessage .messages-thread-and-controls .send-and-media-mode-container {
    position: relative;
    width: 100px;
    height: calc(100% - 8px);
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    z-index: 1;
    padding: 4px;
}

.DirectMessage .messages-thread-and-controls .send-and-media-mode-container .media-mode {
    position: relative;
    width: 100%;
    height: calc(35%);
    margin-top: 4px;
    /*  */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*  */
    border: 1.5px solid rgba(0, 0, 0, 0.03);
    background-color: white;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.02);
}

.DirectMessage .messages-thread-and-controls .send-and-media-mode-container .media-mode img {
    position: relative;
    height: calc(100% - 16px);
    object-fit: contain;
    width: 16px;
    margin-right: 8px;
}

.DirectMessage .messages-thread-and-controls .send-and-media-mode-container .media-mode.active {
    width: calc(100% - 4px);
    height: calc(40% - 4px - 4px);
    border: 2px solid var(--app-blue);
}

.DirectMessage .messages-thread-and-controls .send-and-media-mode-container .send-button {
    position: relative;
    width: 100%;
    height: calc(65%);
    margin-top: 4px;
    /*  */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*  */
    color: white;
    background: linear-gradient(0deg, var(--app-blue-50), var(--app-green-50));
    background-color: var(--app-blue-85);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.02);
}


.DirectMessage .send-button .send-icon-container {
    position: relative;
    width: 12px;
    height: 12px;
    z-index: 0;
    display: grid;
    place-items: center;
    margin-right: 4px;
    opacity: 0.9;
}

.DirectMessage .send-button .send-icon-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.DirectMessage .send-button .send-icon-container.image-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
}

.DirectMessage .dunbar-chat-container .send-and-media-mode-container .media-mode img {
    position: relative;
    width: 20px;
    height: 20px;
}

.DirectMessage .dunbar-chat-container .send-and-media-mode-container .media-mode img {
    margin-right: 8px;
}

.DirectMessage .dunbar-chat-container .send-and-media-mode-container .send-button img {
    margin-bottom: 4px;
}