.auth-view {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 10000;
}

/* ScrollView ContentView */
/* ScrollView ContentView */
/* ScrollView ContentView */
/* ScrollView ContentView */
/* ScrollView ContentView */
/* ScrollView ContentView */
/* ScrollView ContentView */
.auth-view .auth-view-scrollview {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: contain;
    overscroll-behavior: contain;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 4px;
    border-radius: 20px;
}

.auth-view .auth-view-scrollview .auth-view-content-view {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
}


/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
/* Auth View Header */
.auth-view-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    --auth-view-header: 70px;
    height: var(--auth-view-header);
    background: linear-gradient(rgba(0,0,0,0.12), rgba(0,0,0,0));
    transition: 0.3s ease-in-out;
    z-index: 10000;
    /*  */
    display: flex;
    flex-direction: row;
    align-items: center;
    color: transparent;
    border-radius: 4px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
}

.auth-view-header.scrolled { color: var(--app-blue) !important; }

.auth-view-header .auth-view-header-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.auth-view-header .logo-title-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.auth-view-header .logo-title-container .logo-container {
    position: relative;
    --auth-view-logo-height: calc(var(--auth-view-header) - 48px);
    width: var(--auth-view-logo-height);
    height: var(--auth-view-logo-height);
    background-color: transparent;
    border-radius: 8px;
    margin-left: 30px;
    transition: 0.3s ease-in-out;
}

.auth-view-header .logo-title-container .auth-app-title {
    position: relative;
    font-size: 20px;
    margin-left: 12px;
    /* font-family: 'Cabin Sketch', sans-serif !important; */
}

.auth-view-header .header-item-options {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-left: auto !important;
    margin-right: 30px;
}

.auth-footer-option {
    margin-left: 12px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}


.auth-footer-option:first-of-type { margin-left: 0; }

/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
/* Landing Page View */
.auth-view .auth-view-scrollview .auth-view-content-view  .auth-view-landing-container {
    position: relative;
    width: 100vw;
    height: calc(100vh - 4px);
    display: grid;
    place-items: center;
    background-color: white;
    overflow: hidden;
    z-index: 0;
}

/* Landing Background */
/* Landing Background */
/* Landing Background */
.auth-view .auth-view-scrollview .auth-view-content-view  .auth-view-landing-container .landing-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow: hidden;
}

.auth-view .landing-background .landing-bckg-ui-items {
    position: absolute;
    z-index: 2000000;
    width: 170vw;
    height: 150vw;
    top: -50vw;
    left: -50vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transform: rotate(-30deg);
    /*  */
    opacity: 0.25;
    /* background: linear-gradient( white , white); */
    background-color: white;
}

.auth-view .landing-background .landing-bckg-ui-items .background-items {
    position: relative;
    width: fit-content;
    height: fit-content;
    background-color: black;
    padding: 10px 16px;
    border-radius: 40px;
    margin-left: 8px;
    margin-top: 8px;
}

.auth-view .landing-background .landing-bckg-ui-items .background-items .bckg-label {
    color: white;
    font-weight: 600;
    font-size: 22px;
}

/* Landing Content */
/* Landing Content */
/* Landing Content */
/* Landing Content */
/* Landing Content */
/* Landing Content */
/* Landing Content */
.auth-view .auth-view-landing-container .landing-app-title-description {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 3000000000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient( rgba(255, 255, 255, 0.8), white);
    backdrop-filter: blur(1.5px);
}

.auth-view .auth-view-landing-container .landing-app-title-description .landing-icon {
    position: relative;
    width: 80px;
    height: 80px;
    display: grid;
    place-content: center;
    overflow: hidden;
    border-radius: 12px;
    border: 0 solid black;
    background-color: transparent;
    border-radius: 32px;
    background-color: white;
    padding: 24px;
    margin-bottom: 20px;
    border: 1px solid rgb(0, 0, 0, 0.075);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 20px -20px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.auth-view .auth-view-landing-container .landing-app-title-description .landing-icon img {
    position: relative;
    width: 90px;
    height: 90px;
    object-fit: contain;
}

.auth-view .auth-view-landing-container .landing-app-title-description .landing-icon .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: linear-gradient(120deg, var(--app-green), var(--app-blue));
    mix-blend-mode: lighten;
}

.auth-view .auth-view-landing-container .landing-app-title-description .app-type-landing {
    color: rgba(0, 0, 0, 0.3);
}

.auth-view .auth-view-landing-container .landing-app-title-description .app-name-landing {
    position: relative;
    font-size: 100px;
    font-weight: bold;
    color: black;
    color: var(--app-blue);
    /* text-shadow: 0 1px 1px rgb(0, 0, 0, 0.1); */
    font-family: 'Cabin Sketch', sans-serif !important;
    /* font-family: 'Delicious Handrawn', cursive !important;
    font-family: 'Londrina Sketch', sans-serif !important; */
    text-align: center;
}

.auth-view .auth-view-landing-container .landing-app-title-description .app-description-landing {
    position: relative;
    max-width: 65%;
    text-align: center;
    font-size: 34px;
    margin-top: 30px;
    color: rgb(90, 90, 90);
    color: black;
}

.auth-view .header-call-to-action,
.auth-view .app-call-to-action,
.auth-view .auth-view-landing-container .landing-app-title-description .app-call-to-action-landing {
    color: white;
    background: linear-gradient( var(--app-green-40) , var(--app-blue));
    background-color: var(--app-blue);
    font-size: 30px;
    font-weight: 600;
    padding: 16px 20px;
    border: none;
    border-radius: 34px;
    margin-top: 50px;
    animation: bounce 1s infinite;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

@keyframes bounce {
    50% {
        transform: translate(0 , 25%);
    }
    50% {
        transform: scaleY(1.025) translate(0 , 25.5%);
    }
    50.000001% {
        transform: scale(0.9975 ,1.025) translate(0 , 25.5%);
    }
    100% {
        transform: translateY(0);
    }
}

.auth-view .header-call-to-action {
    position: relative;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: auto;
    margin-right: 20px;
    background: linear-gradient(90deg, var(--app-red-50), var(--app-yellow-50), var(--app-blue-50), var(--app-green-50));
    background-color: var(--app-blue);
    animation: none;
    font-size: 17px;
    box-shadow: none;
    padding: 12px;
    transition: 0.3s;
    font-weight: 600;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    padding: 12px 20px;
}

.advertise-call-to-action {
    position: relative;
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: auto;
    margin-right: 30px;
    background: linear-gradient(90deg, var(--app-green-50), var(--app-blue-50));
    background-color: var(--app-green);
    animation: none;
    font-size: 17px;
    box-shadow: none;
    padding: 12px;
    transition: 0.3s;
    font-weight: 600;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    padding: 12px 20px;
    border: none;
    color: white;
    border-radius: 30px;
    margin-left: 0;
}

.auth-view .app-call-to-action {
    animation: none;
    box-shadow: none;
    background: linear-gradient(0deg, var(--app-blue-10), var(--app-blue-30));
    background-color: var(--app-green);
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 0px, rgba(0, 0, 0, 0.01) 0px 0px 8px;
    /* bord
    er: 0.25px solid white; */
}

/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */
/* Further Insight Further Insight Further Insight Further Insight */

.auth-view .further-insight {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    place-items: center;
    background-color: white;
}

.auth-view .further-insight .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.auth-view .further-insight .background-image img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.075;
}


.auth-view .further-insight .further-insight-container {
    position: relative;
    width: calc(100% - 60px);
    padding: 30px; 
    height: calc(100vh - 60px);
    background:  linear-gradient(white, white, var(--app-blue-15), var(--app-blue-25) , var(--app-blue-15), white, white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.auth-view .further-insight .insight {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 60%;
    padding: 30px;
}

.auth-view .further-insight .insight .insight-item {
    margin-top: 40px;
    max-width: 90%;
    font-weight: 600;
    font-size: 34px;
    color: black;
}
.auth-view .further-insight .insight .insight-item:first-of-type { margin: 0;}

.location-to-find-app-small.insight-local {
    position: relative;
    margin-top: 24px;
    right: 12px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
}

.location-to-find-app-small.insight-local a:last-of-type {
    margin: 0;
    margin-left: 8px;
}

.auth-view .further-insight .animation-container {
    position: relative;
    width: 500px;
    height: 500px;
    background:  linear-gradient(transparent, transparent, rgba(255, 255, 255, 0.75), transparent, transparent);
    background-color: transparent;
    display: grid;
    place-items: center;
    border-radius: 0%;
    padding: 12px;
    border: 1.5px solid rgb(0, 0, 0, 0.015);
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
}

@media (max-width: 940px) and (min-width: 560px) {
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .invite-content .location-to-find-app {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h1 {
        font-size: 30px;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .invite-content .location-to-find-app a:last-of-type {
        margin: 0;
        margin-top: 12px;
    }
}

@media (max-width: 860px) and (min-width: 560px) {
    .auth-view .further-insight .animation-container {
        visibility: hidden;
    }
}

@media (max-width: 560px) {
    .auth-view .further-insight {
        width: 100vw;
        height: fit-content;
        display: flex;
    }

    .auth-view .further-insight .further-insight-container {
        width: 100%;
        flex-direction: column;
        padding: 0;
    }

    .auth-view .further-insight .insight {
        padding: 0;
        width: 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
    }

    .auth-view .further-insight .insight .insight-item {
        margin-top: 20px;
        max-width: 90%;
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
    }

    .location-to-find-app-small.insight-local {
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .location-to-find-app-small.insight-local a:last-of-type {
        margin: 0;
        margin-left: 8px;
    }

    .location-to-find-app-small.insight-local a {
        padding: 30px;
    }

    .auth-view .further-insight .animation-container {
        position: relative;
        width: 300px;
        height: 300px;
        background:  linear-gradient(rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
        background-color: transparent;
        display: grid;
        place-items: center;
        border-radius: 0%;
        padding: 12px;
        border: 1.5px solid rgb(0, 0, 0, 0.015);
        box-shadow: none;
        margin-top: 20px;
    }
}

/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
/* Bottom View Sign In / Up and Content Example Container */
.auth-view .auth-view-content-view .auth-and-content-ex-container {
    position: relative;
    /* background: linear-gradient(white, rgba(250, 250, 250), rgba(245, 245, 245)); */
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view {
    position: relative;
    width: fit-content;
    height: calc(100vh - 80px);
    top: 20px;
    background-color: transparent;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 8px;
    /* background: linear-gradient(white, rgba(250, 250, 250), rgba(245, 245, 245)); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 100px;
    padding-left: 60px;
    padding-right: 60px;
    /* animation: scale 1.25s infinite; */
}


.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .app-screenshot {
    position: relative;
    height: 90%;
    width: fit-content;
    margin-right: 20px;
    border-radius: 0%;
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .app-screenshot img {
    position: relative;
    height: 100%;
    width: fit-content;
    object-fit: contain;
    background-color: transparent;
}

@media (max-width: 1200px) {
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .app-screenshot {
        height: 70%;
    }
}

@media (max-width: 1024px) {
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .app-screenshot {
        height: 50%;
    }
}

@media (max-width: 560px) {
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view {
        flex-direction: column;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .app-screenshot {
        position: relative;
        height: 300px;
        width: fit-content;
        margin-bottom: 20px;
    }

    .invite-items-view .location-to-find-app .location-button {
        width: fit-content;
        height: fit-content;
        margin: 0;
        margin-top: 12px !important;
        box-shadow: none;
        padding: 20px 28px;
        border-radius: 40px;
        font-size: 16px !important;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h1 {
        font-size: 28px !important;
        margin-bottom: 8px !important;
    }

}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .invite-content {
    position: relative;
    width: fit-content;
    height: fit-content;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-0%);
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h1 {
    font-size: 40px;
    background: -webkit-linear-gradient(var(--app-blue), var(--app-green) );
    background: var(--app-blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h2 {
    font-size: 50px;
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view .example-items-container-view {
    position: relative;
    width: calc( 100% - 40px );
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
}

.auth-view .auth-view-content-view .auth-and-content-ex-container .auth-card {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: white;
}

.auth-card .auth-card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.auth-card .auth-card-container .phone-sign-in-up  {
    margin-top: 20px;
}

.auth-card .auth-card-container .phone-sign-in-up .PhoneInput {
    height: 40px;
    width: 100%;
}

.auth-card .auth-card-container .phone-sign-in-up .PhoneInputCountry {
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    /* box-shadow: 0 0 4px 1px rgb(0, 0, 0, 0.25); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.auth-card .auth-card-container .phone-sign-in-up .PhoneInputCountryIcon--border {
    border-radius: 4px;
}

.auth-card .auth-card-container .phone-sign-in-up .PhoneInputCountryIconImg {
    border-radius: 4px;
}

.auth-card .auth-card-container .phone-sign-in-up .PhoneInputCountry:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.auth-card .auth-card-container .phone-sign-in-up .phone-number-code-container,
.auth-card .auth-card-container .phone-sign-in-up .confirmation-code-container {
    display:flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.phone-sign-in-up .phone-number-label {
    margin-bottom: 12px;
    text-align: center;
}

.auth-card .auth-card-container .phone-sign-in-up .confirmation-code-container input,
.auth-card .auth-card-container .phone-sign-in-up .PhoneInput input {
    position: relative;
    height: calc(100% - 8px);
    width: fit-content;
    aspect-ratio: 18 / 3;
    border-radius: 8px;
    border: 1.5px solid rgba(0, 0, 0, 0.15);
    padding: 4px 8px;
}

.auth-card .auth-card-container .phone-sign-in-up .phone-sign-in-up-submit-button {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 12px 16px;
    margin-top: 16px;
    background-color: var(--app-blue);
    color: white;
    font-weight: 600;
    border-radius: 12px;
    border: none;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.auth-card .auth-card-container .google-auth-button {
    position: relative;
    height: 50px;
    aspect-ratio: 18 / 3;
    margin-top: 30px;
    /*  */
    padding: 18px 18px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    /*  */
    background: linear-gradient(90deg, var(--app-blue-50), var(--app-red-50), var(--app-yellow-50), var(--app-blue-50), var(--app-green-50),  var(--app-red-50) );
    background-color: var(--app-blue);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: none;
    border-radius: 70px;
    /*  */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.auth-card .auth-card-container .google-auth-button img {
    position: relative;
    height: 24px;
    aspect-ratio: 1 / 1;
    margin-right: 8px;
}

/* auth-footer */
/* auth-footer */
/* auth-footer */
/* auth-footer */
/* auth-footer */
.auth-footer {
    position: relative;
    width: calc(100% - 40px);
    height: 150px;
    background: linear-gradient( var(--app-blue-5), var(--app-blue-10) );
    background-color: black;
    padding: 20px;
}

.auth-footer .h-stack {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.auth-footer .v-stack {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
/* example-items-container-view */
.auth-view .example-items {
    position: relative;
    width: 100%;
    height: fit-content;
}

.auth-view .example-items .example-items-container {
    width: calc(60vw);
    padding: 0 80px;
}

@media (max-width : 560px) {

    .auth-view .landing-background .landing-bckg-ui-items {
        width: 370vw;
        height: 350vw;
    }

    .auth-view .auth-view-landing-container .landing-app-title-description .app-name-landing {
        font-size: 60px;
    }

    .auth-view .auth-view-landing-container .landing-app-title-description .app-description-landing {
        font-size: 30px;
    }

    .auth-card .auth-card-container {
        height: fit-content;
        margin-top: 12px;
    }

    .auth-card .auth-card-container .phone-sign-in-up {
        margin-top: 0;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container {
        position: relative;
        flex-direction: column;
        height: fit-content;
        margin-bottom: 60px;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view {
        position: relative;
        width: calc(100% - 40px);
        height: fit-content;
        top: 0;
        border-radius: 8px;
        padding: 12px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
    }
    
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h1 {
        font-size: 24px;
        margin-bottom: 14px !important;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h2 {
        font-size: 22px;
    }
}

/*  */
/*  */
/*  */
.invite-items-view .location-to-find-app {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    animation: scale 1.25s infinite;
    margin-bottom: 90px;
}

.invite-items-view .location-to-find-app .location-button {
    position: relative;
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 60px;
    padding: 30px 40px;
    background-color: rgba(0, 0, 0, 0.1);
    background: linear-gradient(var(--app-blue-20), var(--app-green-20));
    background-color: black;
    background-color: rgba(0, 0, 0, 0.2);
    background-color: var(--app-blue-10);
    border-radius: 50px;
    box-shadow: rgba(28, 74, 109, 0.15) 0px 48px 100px 0px;
    transition: 0.3s ease-in-out;
}
.invite-items-view .location-to-find-app .location-button:first-of-type { margin: 0; }
.invite-items-view .location-to-find-app .location-button:hover { transform: scale(1.1); }

.invite-items-view .location-to-find-app .location-button .location-title {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 24px;
    color: white;
    color: black;
    margin-bottom: 20px;
}

.invite-items-view .location-to-find-app .location-button .image-container {
    position: relative;
    height: 60px;
    width: 60px;
    overflow: hidden;
}

.invite-items-view .location-to-find-app .location-button .image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.invite-items-view .location-to-find-app .location-button .image-container .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000;
    width: 100%;
    height: 100%;
}


@media (max-width: 500px) {
    .auth-view .auth-view-landing-container .landing-app-title-description .landing-icon {
        /* width: 64px;
        height: 64px; */
    }

    .auth-view .auth-view-landing-container .landing-app-title-description .app-name-landing {
        font-size: 44px;
        max-width: 90%;
    }
    
    .auth-view .auth-view-landing-container .landing-app-title-description .app-type-landing {
        margin-top: 20px;

    }

    .auth-view .auth-view-landing-container .landing-app-title-description .app-description-landing {
        font-size: 24px;
        max-width: 90%;
    }

    .auth-view .header-call-to-action, .auth-view .app-call-to-action, .auth-view .auth-view-landing-container .landing-app-title-description .app-call-to-action-landing {
        box-shadow: none;
        font-size: 24px;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container {
        width: 100vw;
        height: fit-content;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    
    /* .auth-view .example-items {
        width: 100vw;
        height: 100vh;
    } */

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view {
        width: fit-content;
        height: fit-content;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
    }
    
    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h1 {
        font-size: 32px;
        margin-bottom: 14px !important;
    }

    .auth-view .auth-view-content-view .auth-and-content-ex-container .invite-items-view h2 {
        font-size: 16px;
    }

    .invite-items-view .location-to-find-app {
        width: fit-content;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .invite-items-view .location-to-find-app .location-button {
        width: fit-content;
        height: fit-content;
        margin: 0;
        margin-top: 20px !important;
        box-shadow: none;
        padding: 24px 30px;
        border-radius: 40px;
    }

    .invite-items-view .location-to-find-app .location-button .location-title {
        font-size: 22px;
    }

    .invite-items-view .location-to-find-app .location-button .image-container {
        height: 40px;
        width: 40px;
    }
}

@keyframes scale {
    50% {
        transform: scale(1.01);
        text-shadow: 0 2px 10px 5px rgb(0, 0, 0, 0.05);
    }
    100% {
        transform: translateY(0);
    }
}

.location-to-find-app-small {
    position: absolute;
    top: 12px;
    right: 12px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
}

.location-to-find-app-small .location-button-small {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 20px;
    padding: 12px 20px;
    background-color: rgba(0, 0, 0, 0.0001);
    /* background: linear-gradient(var(--app-blue-5), var(--app-green-5)); */
    /* background-color: var(--app-blue-10); */
    /* backdrop-filter: blur(4px); */
    border-radius: 20px;
    /* box-shadow: rgba(28, 74, 109, 0.15) 0px 48px 100px 0px; */
    transition: 0.3s ease-in-out;
    border: 2px solid var(--app-blue);
}
.location-to-find-app-small .location-button-small:first-of-type { margin: 0; }
.location-to-find-app-small .location-button-small:hover { transform: scale(1.1); }

.location-to-find-app-small .location-button-small .location-title {
    position: relative;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    color: white;
    color: black;
    margin-left: 8px;
}

.location-to-find-app-small .location-button-small .image-container {
    position: relative;
    height: 20px;
    width: 20px;
    overflow: hidden;
}

.location-to-find-app-small .location-button-small .image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media  (max-width: 500px) {
    .location-to-find-app-small {
        position: absolute;
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: row;
    }

    .location-to-find-app-small .location-button-small {
        margin-left: 12px;
        padding: 8px 16px;
        border-radius: 14px;
        background-color: rgba(0, 0, 0, 0.1);
        background: none;
        background-color: var(--app-blue-5);
    }

    .location-to-find-app-small .location-button-small .location-title {
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 0;
        overflow: hidden;
    }
}


/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
/* Auth Jump In Container */
.auth-jump-in-pop-out {
    position: absolute; 
    top: 0; 
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000000000;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0,0,0,0.2);
    transition: 0.3s ease-in-out;
    -webkit-transform: 0.3s ease-in-out;
    backdrop-filter: blur(5px);
}

.auth-jump-in-pop-out .auth-jump-in-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 53%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    transition: 0.3s ease-in-out;
    -webkit-transform: 0.3s ease-in-out;
}

.auth-jump-in-pop-out .close-jumpin-button {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 64px;
    width: 64px;
    font-size: 24px;
    border: none;
    background: white;
    border-radius: 15px;
    z-index: 1000000;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    transition: 0.3s ease-in-out;
    display: grid;
    place-items: center;
    padding: 0;
    margin: 0;
}
.auth-jump-in-pop-out .close-jumpin-button:hover {
    transform: scale(1.1);
}






/* Shadow Aniamtion text */
@keyframes text_shadowAnimation {
    0% {
        text-shadow: 0 14px 10px rgba(0,0,0,0.28);
    }
    50% {
        text-shadow: 0 7px 5px rgba(0,0,0,0.28);
    }
    100% {
        text-shadow: 0 14px 10px rgba(0,0,0,0.28);
    }
}

/* Shadow Aniamtion text */
@keyframes box_shadowAnimation {
    0% {
        box-shadow: 0 14px 10px rgba(0,0,0,0.28);
    }
    50% {
        box-shadow: 0 7px 5px rgba(0,0,0,0.28);
    }
    100% {
        box-shadow: 0 14px 10px rgba(0,0,0,0.28);
    }
}

@keyframes rotate_waitListButton {
    0% {
        transform: none;
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.shadowAnimatedText {
    animation: text_shadowAnimation 20s ease-in-out infinite;
    font-size: 40px;
}

@media (max-width: 450px) {
    .shadowAnimatedText {
        font-size: 22px;
    }
}
.shadowAnimateItem {
    animation: box_shadowAnimation 2s ease-in-out infinite;
}

/*  */
.waitlist-hstack-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    backdrop-filter: blur(4px);
    border-radius: 10px;
}

.waitlist-label {
    font-size: 18px;
    font-weight: 600;
    margin-right: 12px;
}

.waitlist-input {
    position: relative;
    padding: 10px 20px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    border: 0px solid rgb(0, 0, 0, 0.15);
    border-radius: 10px;
    min-width: 300px;
    font-size: 18px;
    max-width: calc(100% - 40px);
}

.waitlist-button {
    color: white;
    background: linear-gradient(var(--app-yellow-40) , var(--app-green), 90deg);
    background-color: var(--app-green);
    font-size: 20px;
    font-weight: 600;
    padding: 16px 24px;
    border: none;
    border-radius: 34px;
    margin-left: 20px;
    /* animation: box_shadowAnimation 2s ease-in-out infinite; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.lodaing-waitlist-button {
    animation: rotate_waitListButton 3s ease-in-out infinite;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 0px -12px, rgba(0, 0, 0, 0.3) 0px 0px 0px -0px;
}

/* Mobile Last */
@media (max-width: 550px) {
    .waitlist-hstack-container {
        flex-direction: column;
    }

    .waitlist-label {
        margin: 0;
        margin-bottom: 12px;
    }
    
    .waitlist-input {
        padding: 8px 0;
    }
    
    .waitlist-button {
        margin: 0;
        margin-top: 20px;
    }
}