.image-view-scrollview {
    position: relative;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    margin-top: 12px;
    display: grid;
    place-items: center;
    border-radius: 20px;
    background: linear-gradient(rgba(0, 0, 0, 0.05), var(--app-blue-5));
    padding: 4px 8px;
}

.image-view-scrollview .image-view-content-view {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin: 4px;
}

.image-view-scrollview .image-view-content-view.single {
    width: 100%;
}

.image-view-scrollview .image-view-content-view img {
    position: relative;
    height: 600px;
    width: fit-content;
    max-width: 100%;
    border-radius: 20px;
    /* border: 2px solid black; */
    margin-left: 8px;
    transition: 0.3s ease-in-out; 
}
.image-view-scrollview .image-view-content-view img:hover {
    transform: scale(1.005);
}
.image-view-scrollview .image-view-content-view img:first-of-type {
    margin: 0;
}
.image-view-scrollview .image-view-content-view img:last-of-type{
    margin-right: 20px;
}

.image-view-scrollview .image-view-content-view img.single-image {
    position: relative;
    height: 100%;
    max-height: 500px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}


/* Image Horizontal */
/* Image Horizontal */
/* Image Horizontal */
/* Image Horizontal */
.content-items.horizontal .image-view-scrollview {
    --content-items-horizontal-height: 520px;
    position: relative;
    width: calc(100% - 20px);
    height: var(--content-items-horizontal-height);
    display: grid;
    place-items: center;
    padding: 0 4px;
    overflow: hidden;
    border: 1px solid black;
}

/*  */
/*  */
/*  */
.content-items.horizontal .image-view-scrollview .imagview-single-bkg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: visible;
    opacity: 0.5;
}

.content-items.horizontal .image-view-scrollview .imagview-single-bkg .single-image-bkg {
    filter: blur(8px);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*  */
/*  */
/*  */
.content-items.horizontal .image-view-scrollview .image-view-content-view {
    display: grid;
    place-items: center;
    padding: 0;
}

.content-items.horizontal .image-view-scrollview .image-view-content-view img {
    position: relative;
    height: fit-content;
    max-height: calc( var(--content-items-horizontal-height) - 8px);
    width: 100%;
    margin: 0;
    object-position: top;
}
.content-items.content-items.horizontal .like-and-emoji-responses-container {
    margin-top: auto;
}
.content-items.horizontal .v-stack-item .content-title {
    margin-top: 20px;
    font-size: 24px;
}
.content-items.horizontal .v-stack-item .content-post {
    font-size: 18px;
}
.content-items.horizontal .like-and-emoji-responses-container {
    overflow: scroll;
    overflow-y: visible;
}
.content-items.horizontal .like-and-emoji-responses-container .like-and-emoji-responses-hstack .emoji-response-options:hover {
    transform: none;
}