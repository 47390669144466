.DunbarSubThreadPopUp {
    position: absolute;
    left: calc(50%);
    transform: translate(-50%, -60%);
    z-index: 30000000000000000;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    padding: 30px 34px;
    border-radius: 12px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    border: 0.75px solid rgba(17, 17, 26, 0.05);
}

.DunbarSubThreadPopUp .background-view {
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    left: 0;
    z-index: 0;
    overflow: visible;
    opacity: 0.75;
}

.DunbarSubThreadPopUp .dunbar-subthread-popup-content-container {
    position: relative;
    z-index: 20;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
}

.DunbarSubThreadPopUp .pop-up-title-description h3 {
    position: relative;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.95);
}

.DunbarSubThreadPopUp .pop-up-title-description p {
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 7px;
}

/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
/* Form Items */
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content {
    position: relative;
    margin-top: 20px;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    max-width: 500px;
}

/* Subthread Image Controlls */
/* Subthread Image Controlls */
/* Subthread Image Controlls */
/* Subthread Image Controlls */
/* Subthread Image Controlls */
/* Subthread Image Controlls */
/* Subthread Image Controlls */
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image {
    position: relative;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50px;
    border: 1.5px solid black;
    background-color: gray;
    z-index: 0;
    padding: 0;
    margin: 0;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image img {
    position: relative;
    width: 100%;
    height: 100%;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .plus-icon {
    position: absolute;
    z-index: 20000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    display: grid;
    place-items: center;
    --icon-dimensions-long: 10px;
    --icon-dimensions-short: 2px;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .plus-icon .v,
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .plus-icon .h {
    position: relative;
    background-color: white;
    border-radius: 2px;
    display: block;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .plus-icon .h {
    height: var(--icon-dimensions-short);
    width: var(--icon-dimensions-long);
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image .plus-icon .v {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 1px);
    height: var(--icon-dimensions-long);
    width: var(--icon-dimensions-short);
}

/* Title Field */
/* Title Field */
/* Title Field */
/* Title Field */
/* Title Field */
/* Title Field */
/* Title Field */
/* Title Field */
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .title-field {
    position: relative;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px - 8px - 2px);
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .title-field label {
    position: relative;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    margin-left: 2px;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .title-field input {
    position: relative;
    font-size: 14px;
    padding: 5px;
    color: black;
    border: none;
    background: linear-gradient(90deg, lightgray, rgb(205, 205, 205));
    background-color: lightgray;
    width: 200px;
    border-radius: 3px;
    margin-top: 4px;
    width: 100%;
}

/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */
/* Decription Field */


.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .decription-field {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 16px;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .decription-field label {
    position: relative;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .decription-field textarea {
    position: relative;
    font-size: 13px;
    padding: 4px;
    color: black;
    border: none;
    background: linear-gradient(lightgray, rgb(205, 205, 205));
    background-color: lightgray;
    width: 500px;
    height: 200px;
    border-radius: 5px;
    resize: none;
    margin-top: 8px;
}


/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
/* Close And Intiate Buttons */
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .subthread-itiation-buttons {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .subthread-itiation-buttons button {
    position: relative;
    /*  */
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    /*  */
    font-weight: 600;
    font-size: 14px;
    color: white;
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .subthread-itiation-buttons .cancel {
    background-color: var(--app-red-10);
    color: var(--app-red);
}

.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .subthread-itiation-buttons .initiate {
    background-color: var(--app-blue);
    margin-left: 12px;
}

/* Explaination */
/* Explaination */
/* Explaination */
/* Explaination */
/* Explaination */
/* Explaination */
.DunbarSubThreadPopUp .sub-thread-pop-up-form-content .explaination {
    position: relative;
    color: gray;
    font-size: 11px;
    max-width: 500px;
}



@media (max-width: 1034px) {
    .DunbarSubThreadPopUp {
        width: calc(100% - 48px);
        /* height: calc(100% - 16px); */
        padding: 20px 12px;
    }

    .DunbarSubThreadPopUp .background-view {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .DunbarSubThreadPopUp .dunbar-subthread-popup-content-container {
        width: calc(100%);
    }

    .DunbarSubThreadPopUp .sub-thread-pop-up-form-content {
        width: 100%;
    }

    .DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form {
        width: calc(100% - 8px);
    }

    .DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .sub-thread-image-and-title-container .sub-thread-image {
        border-radius: 18px;
    }

    .DunbarSubThreadPopUp .pop-up-title-description h3 {
        position: relative;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.95);
    }

    .DunbarSubThreadPopUp .pop-up-title-description p {
        font-size: 13px;
    }

    /* .DunbarSubThreadPopUp .pop-up-title-description p {
        width: 0;
        height: 0;
        overflow: hidden;
        margin: 0;
    } */

    .DunbarSubThreadPopUp .sub-thread-pop-up-form-content .sub-thread-form .decription-field textarea {
        width: calc(100%);
        height: 200px;
        margin-top: 8px;
        font-size: 14px;
    }
}