.startDirectMessage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000000000000;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.startDirectMessage .directMessage-dismissable-view {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(1.5px);
    background-color: rgba(0,0,0,0.075);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}

/* Direct Message Pop-up */
.startDirectMessage .direct-message-pop-up {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: fit-content;
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 30px 30px;
    border-radius: 20px;
    width: 500px;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.startDirectMessage .direct-message-pop-up .message-controlls-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.startDirectMessage .direct-message-pop-up .pop-up-title {
    position: relative;
    font-weight: 600;
    color: black;
    font-size: 18px;
    margin-bottom: 12px;
}

.startDirectMessage .direct-message-pop-up .message-controlls-container .message-field {
    position: relative;
    width: calc(100% - 24px - 50px - 8px);
    height: 40px;
    background-color: rgba(0,0,0, 0.075);
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    resize: none;
}

.startDirectMessage .direct-message-pop-up .message-controlls-container .send-button {
    position: relative;
    width: 50px;
    height: 34px;
    margin-left: 12px;
    background-color: var(--app-blue);
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 8px;
}




















/* mobileStartDirectMessage */
.startDirectMessage.mobileStartDirectMessage .direct-message-pop-up {
    width: calc(100vw - 60px);
    height: calc(100vw - 60px);
    box-shadow: none;
}