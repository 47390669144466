/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
/* Post Controller */
.post-controller-container {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background: linear-gradient(transparent, rgba(0,0,0,0.5)); */
    /* background-color: rgba(0, 0, 0, 0.35); */
    /* background-color: var(--app-blue); */
    background-color: rgba(255, 255, 255, 0.65);
    z-index: 10000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    backdrop-filter: blur(24px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.post-controller-container .close-post-controller {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background-color: rgba(0, 0, 0, 0.25);
    display: grid;
    place-items: center;
    font-family: "barlow";
    color: white;
    font-weight: 700;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    backdrop-filter: blur(10px);
    z-index: 10000;
}

.post-controller-container .post-controller-content-instructions-view {
    position: relative;
    width: calc(20%);
    height: calc(100%);
    background: none;
    background-color: none;
    border: 1px solid rgb(240, 240, 240);
    overflow: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

.post-controller-container .post-controller-content-instructions-view h2 {
    width: calc(100% - 24px);
    padding: 12px;
}

.post-controller-container .post-ctrlr-instuction-content-view {
    position: relative;
    width: calc(100% - 24px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding: 12px;
}

.post-controller-container .close-post-controller h1 {
    font-family: "barlow";
    font-size: 30px;
}

.close-post-controller:hover { 
    background-color: rgba(0, 0, 0, 0.45);
    transform: scale( 1.15 );
    border: 1px solid rgba(255, 255, 255, 0.25);
 }

 /* Post Content */
.post-controller-container .post-controller-content-scroll-view { 
    position: relative;
    width: calc(80%);
    height: calc(100%);
    background: none;
    background-color: none;
    /* border-top-right-radius: 12px;
    border-bottom-right-radius: 12px; */
    border: 1px solid rgb(240, 240, 240);
    /* box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
}

.post-controller-container .post-controller-content-view {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.post-controller-content-view .post-type {
    position: relative;
    height: fit-content;
    width: calc(100% - 60px);
    margin-left: 0;
    display: grid;
    place-items: center;
}

.post-controller-content-view .post-type .post-type-select {
    position: relative;
    margin-top: 12px;
    height: 50px;
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
}

.post-controller-content-view .post-type .post-type-select .post-type-option {
    position: relative;
    width: fit-content;
    height: calc(100% - 4px - 8px);
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 16px;
    color: black;
    padding: 8px 12px;
    border-radius: 12px;
    box-shadow: 0 0 5px 1px rgb(0, 0, 0, 0.09);
    margin-left: 12px;
}

.post-controller-content-view .post-type .post-type-select .post-type-option:first-of-type {
    margin-left: 0;
}

.post-controller-content-view .post-type .post-type-select .post-type-option.active {
    border: 2px solid var(--app-blue);
    box-shadow: 0 0 8px 2px rgb(0, 0, 0, 0.15);
}






.post-controller-container .community-options-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
    width: calc(100% - 40px);
}

.post-controller-container .community-options-container .title-label {
    position: relative;
    font-weight: 600;
    font-size: 16px;
}

.post-controller-container .community-options-container .community-items-container {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.post-controller-container  .community-options-container .community-items-container .community-option-item {
    position: relative;
    width: fit-content;
    height: fit-content;
    background-color: var(--app-blue);
    padding: 8px 12px;
    margin-left: 8px;
    border-radius: 20px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.post-controller-container  .community-options-container .community-items-container .community-option-item.active {
    border: 2px solid black;
}






.post-controller-content-view .post-title-container {
    position: relative;
    width: calc( 100% - 40px );
    margin-left: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.post-controller-content-view .post-title-container label {
    position: relative;
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.post-controller-content-view .post-title-container label.description {
    font-weight: 500;
}

.post-controller-content-view .post-title-container input {
    position: relative;
    height: 40px;
    width: 100%;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 700;
    margin-top: 8px;
}

/* Modes Container */
/* Modes Container */
/* Modes Container */
/* Modes Container */
.post-controller-container .post-text-content {
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    margin-left: 20px;
    height: fit-content;
}

.post-controller-container .mode-items-container {
    position: relative;
    display: grid;
    place-items: center;
    height: calc(100% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
}

.post-controller-container .mode-items-container .mode-items-v-stack-grid {
    position: relative;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-controller-container .mode-items-container .mode-item {
    display: grid;
    place-items: center;
    color: black;
    text-align: center;
    word-spacing: 1em;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100px;
    height: 80px;
    margin-top: 12px;
    border-radius: 12px;
    border: 2.5px solid rgba(128, 128, 128, 0.1);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}
.post-controller-container .mode-items-container .mode-item:last-of-type { margin-right: 0; }

.post-controller-container .mode-items-container .mode-item.active {
    border: 2.5px solid var(--app-blue);
    height: 77.5px;
}


.post-controller-container .post-text-content-field-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 12px;
    background-color: var(--setting-items-color);
    padding: 12px 16px;
    border-radius: 8px;
}

.post-controller-container .post-text-content-field-container .title {
    position: relative;
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.post-controller-container label.description,
.post-controller-container .post-text-content-field-container label.description {
    position: relative;
    width: fit-content;
    height: fit-content;
    color: gray;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: 4px;
}

.post-controller-container .post-text-content-field-container textarea {
    position: relative;
    width: calc(100% - 24px);
    height: calc(300px);
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    font-size: 14px;
    color: black;
    padding: 8px 12px;
    font-family: 'barlow';
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    resize: none;
}

.post-controller-container .post-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 20px 36px;
    background-color: none;
    background: linear-gradient(#2093ff, #1e8af0);
    background: linear-gradient(transparent, rgba(0,0,0,0.15));
    background-color: var(--app-blue);
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    box-shadow: 0 0 8px 2px #11539020;
}



#my-uploader .btn {
    background: var(--app-yellow) !important;
    padding: 1.3em;
    color: white;
    border-radius: .35em;
    border: 0;
    cursor: pointer;
    font-weight: 600;
}

:host {
    border: none !important;
}


/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
/* Image Posts */
.post-controller-content-view .image-post-upload-container {
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    overflow-x: scroll;
    overscroll-behavior: contain;
    position: relative;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 20px;
}

.post-controller-content-view .image-post-upload-container .image-post-upload-content-view {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
}

.post-controller-content-view .image-post-upload-content-view .post-image-button-option  {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    border: none;
    background-color: white;
    z-index: 0;
    padding: 0;
    margin-left: 20px;
    box-shadow: 0 0 8px 2px var(--app-blue-10);
}
.post-controller-content-view .image-post-upload-content-view .post-image-button-option:first-of-type { margin: 0; }

.post-controller-content-view .image-post-upload-content-view  .post-image-button-option img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.post-controller-content-view .image-post-upload-content-view  .post-image-button-option .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
}

.post-controller-content-view .image-post-upload-content-view  .post-image-button-option .plus-icon {
    position: absolute;
    z-index: 20000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    display: grid;
    place-items: center;
    --icon-dimensions-long: 20px;
    --icon-dimensions-short: 3px;
}

.post-controller-content-view .image-post-upload-content-view  .post-image-button-option .plus-icon .v,
.post-controller-content-view .image-post-upload-content-view  .post-image-button-option .plus-icon .h {
    position: relative;
    background-color: black;
    border-radius: 2px;
    display: block;
}

.post-controller-content-view .image-post-upload-content-view  .post-image-button-option .plus-icon .h {
    /* height: var(--icon-dimensions-short);
    width: var(--icon-dimensions-long); */
    left: calc(50% - 10px);
    top: calc(50% - 1.5px);
}

.post-controller-content-view .image-post-upload-content-view .post-image-button-option .plus-icon .v {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 1.5px);
    height: var(--icon-dimensions-long);
    width: var(--icon-dimensions-short);
}